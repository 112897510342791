/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from "react";

export default class ArrayField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      array: props.array
    };
  }

  handleChangeElement(value, index) {
    const array = this.state.array.slice();
    array[index] = value;
    this.setState({ array });
  }

  handleRemove(index) {
    const array = this.state.array.slice();
    array.splice(index, 1);
    this.setState({ array });
  }

  handleUp(index) {
    if (index === 0) {
      return;
    }

    const oldArray = this.state.array;
    const array = oldArray.slice();
    array[index] = oldArray[index - 1];
    array[index - 1] = oldArray[index];
    this.setState({ array });
  }

  handleDown(index) {
    const oldArray = this.state.array;
    if (index === oldArray.length - 1) {
      return;
    }

    const array = oldArray.slice();
    array[index] = oldArray[index + 1];
    array[index + 1] = oldArray[index];
    this.setState({ array });
  }

  handleAdd() {
    const array = this.state.array.slice();
    array.push("");
    this.setState({ array });
  }

  render() {
    const { array } = this.state;
    const { name } = this.props;

    const fields = array.map((arrayItem, index) => (
      <li key={index}>
        <input
          type="text"
          name={`${name}[]`}
          value={arrayItem}
          onChange={ev => this.handleChangeElement(ev.target.value, index)}
        />
        <button
          type="button"
          className="button-up"
          onClick={() => this.handleUp(index)}
        >
          &#x25b2;
        </button>
        <button
          type="button"
          className="button-down"
          onClick={() => this.handleDown(index)}
        >
          &#x25bc;
        </button>
        <button
          type="button"
          className="button-remove"
          onClick={() => this.handleRemove(index)}
        >
          &#x2718;
        </button>
      </li>
    ));

    return (
      <div>
        <div>
          <ul>{fields}</ul>
        </div>
        <div>
          <button
            type="button"
            className="button-add"
            onClick={() => this.handleAdd()}
          >
            &#x2795;
          </button>
        </div>
      </div>
    );
  }
}
