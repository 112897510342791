/* eslint-disable react/destructuring-assignment */
import React from "react";

export default class ActionBar extends React.Component {
  render() {
    return (
      <div className="action-bar small">
        <button
          type="button"
          onClick={this.props.handleCancel}
          className="text-button destructive"
        >
          Cancel
        </button>
        <button type="submit">Save</button>
      </div>
    );
  }
}
