/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import AutocompleteField from "../Autocomplete/AutocompleteField";
import FileField from "./FileField";

export default class AttachmentField extends React.Component {
  constructor(props) {
    super();
    const { document } = props;
    this.state = {
      selectedTab: document ? "select" : "remove"
    };
  }

  handleTabClick(key) {
    this.setState({ selectedTab: key });
  }

  renderTab(key, title) {
    const { selectedTab } = this.state;
    const selected = key === selectedTab;
    const className = `tab ${selected ? "selected" : "unselected"}`;
    return (
      <div
        className={className}
        key={key}
        onClick={() => this.handleTabClick(key)}
      >
        {title}
      </div>
    );
  }

  render() {
    const {
      document,
      name,
      allowCreate,
      allowSelect,
      allowUpload,
      allowNone
    } = this.props;
    const { selectedTab } = this.state;

    const tabs = [
      allowCreate &&
        this.renderTab(
          "create",
          <div>
            <i className="fas fa-plus"> </i> Create
          </div>
        ),
      allowSelect &&
        this.renderTab(
          "select",
          <div>
            <i className="fas fa-check"> </i> Select
          </div>
        ),
      allowUpload &&
        this.renderTab(
          "upload",
          <div>
            <i className="fas fa-upload"> </i> Upload
          </div>
        ),
      allowNone &&
        this.renderTab(
          "remove",
          <div>
            <i className="fas fa-trash"> </i> None
          </div>
        )
    ];

    let content;
    const contentStyle = {};
    switch (selectedTab) {
      case "create":
        content = (
          <AutocompleteField
            name={`${name}[template_id]`}
            placeholder="Search for template"
            url="/templates.json"
            labelField="name"
            valueField="id"
            defaultLabel={document && document.name}
            defaultValue={document && document.id}
          />
        );
        break;

      case "select":
        content = (
          <AutocompleteField
            name={`${name}[document_id]`}
            placeholder="Search for document"
            url="/documents.json"
            labelField="name"
            valueField="id"
            defaultLabel={document && document.name}
            defaultValue={document && document.id}
          />
        );
        break;

      case "upload":
        content = <FileField name={`${name}[file]`} />;
        break;

      case "remove":
        content = (
          <input type="hidden" name={`${name}[_destroy]`} value="true" />
        );
        contentStyle.display = "none";
        break;

      default:
      // no default case
    }

    return (
      <div className="attachment-field">
        <div className="tabs">{tabs}</div>
        <div className="tab-content" key={selectedTab} style={contentStyle}>
          {content}
        </div>
      </div>
    );
  }
}
