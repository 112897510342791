import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { fetchCall } from "../../../../utils/fetchCall";
import { fetchReducer } from "../../../../reducers/fetchReducer";
import { gray } from "../../../../styles/colors";

const Header = styled.div``;

const ReferencesDiv = styled.div`
  margin-top: 3rem;
`;

const EvidenceSelectDiv = styled.div`
  border-left: 1px solid ${gray};
  padding-left: 1rem;
`;

const EvidenceItem = styled.div`
  margin-top: 0.75rem;
`;

const RequestBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 6rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -4px 3px ${gray};
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${gray};
`;

export default function EvidenceRequestForm(props) {
  const {
    petitionId = null,
    evidenceOptions = [],
    setRequestStatus,
    setEvidenceRequest,
    errors,
    setErrors
  } = props;

  const [selectedEvidence, setSelectedEvidence] = useState(
    evidenceOptions
      .map(option => option.evidenceItems)
      .flat()
      .map(a => ({ type: a.type, id: a.id }))
  );

  const [fetchState, dispatch] = useReducer(fetchReducer, {
    success: false,
    loading: false,
    response: null
  });

  const handleChange = e => {
    if (e.target.checked) {
      setSelectedEvidence([
        ...selectedEvidence,
        { type: e.target.name, id: e.target.value }
      ]);
    }
    if (!e.target.checked) {
      setSelectedEvidence(
        selectedEvidence.filter(item => item.id !== e.target.value)
      );
    }
  };

  const handleSubmit = async () => {
    if (!selectedEvidence) {
      return alert("Add a piece of evidence to request first!");
    }
    dispatch({ type: "FETCH_STARTED" });
    try {
      const url = `${Routes.petition_evidence_request_path(petitionId)}`;
      const params = {
        evidenceItems: selectedEvidence
      };

      const response = await fetchCall(url, {
        method: "POST",
        body: JSON.stringify(params)
      });

      if (!response.success) {
        dispatch({ type: "FETCH_ERROR" });
        setRequestStatus("FAILURE");
        setErrors([
          ...errors,
          {
            message:
              "Your evidence request failed.  Please contact a Legalpad engineer for assistance."
          }
        ]);
      }

      if (response.success) {
        dispatch({ type: "FETCH_COMPLETE", response });
        setEvidenceRequest(response.data);
        setRequestStatus("SUBMITTED");
      }
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      setRequestStatus("FAILURE");
      setErrors([
        ...errors,
        {
          message:
            "Your evidence request failed.  Please contact a Legalpad engineer for assistance."
        }
      ]);
    }
  };

  return (
    <>
      <Header>
        <h1>Add Evidence</h1>
        <p>Here are the evidence references we found.</p>
      </Header>
      {evidenceOptions.map(option => (
        <ReferencesDiv key={option.evidenceType}>
          <p>{option.evidenceType}</p>
          <EvidenceSelectDiv>
            <small>{option.subHeader}</small>
            {option.evidenceItems.map(item => (
              <EvidenceItem key={item.id}>
                <label className="input-button-label">
                  <input
                    type="checkbox"
                    name={item.type}
                    value={item.id}
                    onChange={e => handleChange(e)}
                    checked={selectedEvidence.map(a => a.id).includes(item.id)}
                  />
                  <div className="checkbox">{item.displayValue}</div>
                </label>
              </EvidenceItem>
            ))}
          </EvidenceSelectDiv>
        </ReferencesDiv>
      ))}
      <RequestBox>
        {!fetchState.loading && (
          <>
            <div>{selectedEvidence.length} pieces of evidence selected</div>
            <button
              type="button"
              style={{ marginLeft: "1rem" }}
              onClick={() => handleSubmit()}
            >
              Request Evidence
            </button>
          </>
        )}
        {fetchState.loading && (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        )}
      </RequestBox>
    </>
  );
}

EvidenceRequestForm.propTypes = {
  petitionId: PropTypes.string,
  evidenceOptions: PropTypes.array,
  setRequestStatus: PropTypes.func,
  setEvidenceRequest: PropTypes.func,
  errors: PropTypes.array,
  setErrors: PropTypes.func
};
