import React, { useState } from "react";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import { IndexRow } from "../Shared/IndexRow/IndexRow";
import { FormInput } from "../Shared/Forms/index";
import ButtonSecondary from "../Shared/ButtonSecondary";
import request from "../../services/request";
import { CheckboxItem } from "../Shared/CheckBox";

export default function EmployeeForm(props) {
  const popup = useAlert();
  const { setEmployees, setSortBy } = props;

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  const handleFormChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const [isAttorney, setIsAttorney] = useState(false);

  const [formVisible, setFormVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const createEmployee = async () => {
    setLoading(true);
    try {
      const { firstName, lastName, email, title } = formState;

      const requestBody = {
        person: {
          first_name: firstName,
          last_name: lastName,
          is_attorney: isAttorney,
          email,
          title
        }
      };

      const newEmployeeJson = await request(Routes.employees_path(), {
        body: requestBody
      });

      setEmployees(prev => [newEmployeeJson, ...prev]);
      setSortBy("createdAt");

      popup.show(
        "New employee added. A welcome and sign up email is headed their way!",
        { type: "success" }
      );

      setFormState({
        firstName: "",
        lastName: "",
        email: "",
        title: ""
      });
      setFormVisible(false);
      setIsAttorney(false);
    } catch (error) {
      popup.show(error.errors?.toString(), { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!formVisible && (
        <tr>
          <td>
            <ButtonSecondary
              text="Add employee"
              onClick={() => setFormVisible(true)}
            />
          </td>
        </tr>
      )}
      {formVisible && (
        <IndexRow>
          <td>
            <FormInput
              width="10rem"
              name="firstName"
              type="text"
              placeholder="First Name"
              value={formState.firstName}
              onChange={e => handleFormChange(e)}
            />
          </td>
          <td>
            <FormInput
              width="10rem"
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={formState.lastName}
              onChange={e => handleFormChange(e)}
            />
          </td>
          <td>
            <FormInput
              width="10rem"
              name="email"
              type="text"
              placeholder="Email"
              value={formState.email}
              onChange={e => handleFormChange(e)}
            />
          </td>
          <td>
            <FormInput
              width="10rem"
              name="title"
              type="text"
              placeholder="Title"
              value={formState.title}
              onChange={e => handleFormChange(e)}
            />
          </td>
          <td>
            <CheckboxItem>
              <input
                id="is-attorney"
                type="checkbox"
                checked={isAttorney}
                onChange={() => setIsAttorney(!isAttorney)}
              />
              <label htmlFor="is-attorney">Employee is attorney</label>
            </CheckboxItem>
          </td>
          <td
            style={{
              textAlign: "right",
              padding: 0
            }}
          >
            {loading && (
              <Loader type="ThreeDots" color="#2680FA" height={18} width={64} />
            )}
            {!loading && (
              <>
                <button
                  type="button"
                  className="small cancel u_margin-right-1rem"
                  onClick={() => setFormVisible(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="small"
                  onClick={() => createEmployee()}
                >
                  Save
                </button>
              </>
            )}
          </td>
        </IndexRow>
      )}
    </>
  );
}
