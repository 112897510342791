import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import createPersistedState from "use-persisted-state";
import { useDebouncedCallback } from "use-debounce";
import { useHotkeys } from "../../../hooks/useHotKeys";
import { fetchCall } from "../../../utils/fetchCall";

const Container = styled.div`
  padding: ${props => (props.padding ? "1rem" : 0)};
  margin: ${props => (props.margin ? "1rem 0" : 0)};
`;
const Input = styled.textarea`
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  width: 100%;
  height: 10rem;
`;

const OuterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SavePrompt = styled.div`
  padding: ${props => (props.padding ? "1rem" : 0)};
  color: #b9bfcb;

  &&& {
    font-size: 14px;
  }

  button {
    margin-left: 1rem;
  }
`;

export default function NoteInput(props) {
  const {
    padding,
    margin,
    source,
    notes,
    setNotes,
    setInputVisible,
    notable,
    notableType,
    consoleOpen,
    isNotesConsole
  } = props;

  const useNoteInputState = createPersistedState(`note-${notable.id}`);

  const [currentStorageValue, setCurrentStorageValue] = useNoteInputState("");

  const [currentInputValue, setCurrentInputValue] = useState(
    currentStorageValue || ""
  );

  const debouncedStorage = useDebouncedCallback(
    value => setCurrentStorageValue(value),
    250
  );

  const handleInputChange = input => {
    setCurrentInputValue(input);
    debouncedStorage(input);
  };

  const inputRef = useRef(null);

  const handleTab = e => {
    if (e.key === "Tab") {
      e.preventDefault();

      const { selectionStart, selectionEnd } = e.target;
      const newInputValue = `${currentInputValue.substring(
        0,
        selectionStart
      )}  ${currentInputValue.substring(selectionEnd)}`;
      handleInputChange(newInputValue);
      if (inputRef.current) {
        inputRef.current.value = newInputValue;
        // eslint-disable-next-line no-multi-assign
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          selectionStart + 2;
      }
    }
  };

  const handleSave = async text => {
    if (!text) {
      alert("add a note first!");
    }

    try {
      const url = `${Routes.root_url({ subdomain: "" })}/notes`;

      const params = {
        text,
        notable_id: notable.id,
        notable_type: notableType,
        source
      };

      const response = await fetchCall(url, {
        method: "POST",
        body: JSON.stringify(params)
      });

      if (!response.success) {
        alert("something went wrong");
      }

      if (response.success) {
        setCurrentInputValue("");
        setCurrentStorageValue("");
        const json = await response.data;
        await setNotes([...notes, json]);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [consoleOpen]);

  useHotkeys(
    "ctrl + enter",
    () => {
      if (document.activeElement === inputRef.current) {
        handleSave(inputRef.current.value);
      }
    },
    [inputRef.current, inputRef.current?.value, notes]
  );

  const ActionPrompt = () => {
    if (isNotesConsole) {
      return (
        <SavePrompt padding={padding}>
          Press CTRL + enter to
          <button
            type="button"
            className="text-button small"
            onClick={() => handleSave(currentInputValue)}
          >
            Save
          </button>
        </SavePrompt>
      );
    }
    return currentInputValue ? (
      <SavePrompt padding={padding}>
        Press CTRL + enter to
        <button
          type="button"
          className="text-button small"
          onClick={() => handleSave(currentInputValue)}
        >
          Save
        </button>
      </SavePrompt>
    ) : (
      <button
        type="button"
        className="text-button small"
        onClick={() => setInputVisible(false)}
      >
        Done
      </button>
    );
  };

  return (
    <Container padding={padding} margin={margin}>
      <Input
        ref={inputRef}
        value={currentInputValue}
        name="note-text-input"
        placeholder="Start typing..."
        onChange={e => handleInputChange(e.target.value)}
        onKeyDown={e => handleTab(e)}
      />
      <OuterDiv>
        <ActionPrompt />
      </OuterDiv>
    </Container>
  );
}

NoteInput.propTypes = {
  source: PropTypes.string,
  notable: PropTypes.object,
  notableType: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  consoleOpen: PropTypes.bool
};
