import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { fetchCall } from "../../../../utils/fetchCall";

const BlockerWrapper = styled.div`
  text-align: center;
  width: 10rem;
  height: 8rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
`;

const Flag = styled.i`
  color: ${props => (props.active ? "#FF5C5C" : "#41CD7E")};
  margin: 0 0 1rem 0;
`;

export default function BlockerFlag(props) {
  const { petitionId, label, isActive } = props;
  const [active, setActive] = useState(isActive);

  const handleClick = async key => {
    const url = Routes.petition_toggle_blocker_url(petitionId, { key });

    const response = await fetchCall(url, { method: "PUT" });

    if (!response.success) {
      alert(`Unexpected error: ${response.error}`);
    }
    if (response.success) {
      setActive(!active);
    }
  };

  return (
    <BlockerWrapper key={label} onClick={() => handleClick(label)}>
      <Flag
        active={active}
        className={`fa fa-2x ${active ? "fa-times-circle" : "fa-check-circle"}`}
      />
      <p>{label}</p>
    </BlockerWrapper>
  );
}

BlockerFlag.propTypes = {
  petitionId: PropTypes.string,
  label: PropTypes.string,
  isActive: PropTypes.bool
};
