import { render, waitFor } from "@testing-library/react";
import PetitionDocuments from "./PetitionDocuments";

describe("PetitionDocuments", () => {
  // eslint-disable-next-line no-console
  window.alert = alert => console.error(alert);

  beforeEach(() => fetch.once(JSON.stringify({})));

  it("renders without crashing", async () => {
    const { container } = render(
      <PetitionDocuments
        sections={[]}
        petitionId="123"
        beneficiary={{ name: "Coach Kline", id: "some-id" }}
        assigneeOptions={[]}
        assigneeId={null}
        documents={[]}
        templates={[]}
      />
    );
    await waitFor(() => expect(container));
  });
});
