import React from "react";
import MaskedInput from "react-text-mask";

const mask = [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export default class FeinField extends React.Component {
  render() {
    const { name, label, value, placeholder } = this.props;
    return (
      <React.Fragment>
        <MaskedInput
          mask={mask}
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
        />
        {label && <label>{label}</label>}
      </React.Fragment>
    );
  }
}
