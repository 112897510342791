/* eslint-disable react/destructuring-assignment */
import React from "react";
import MaskedInput from "react-text-mask";

export default function SsnField(props) {
  /*
    Props: {
      name*<string>:           name of the input
      placeholder<string>:     placeholder value
      value<string>:           default value
      label<string>:           value for the label
      handleInputChange<func>: callback to handle input change
      handleInputBlur<func>:   callback to handle input blur
    }
  */
  const mask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  const { name, label, value, placeholder } = props;

  return (
    <React.Fragment>
      <MaskedInput
        mask={mask}
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={props.handleInputChange}
        onBlur={props.handleInputBlur}
      />
      {label && <label>{label}</label>}
    </React.Fragment>
  );
}
