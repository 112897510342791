import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BlockerFlag from "./BlockerFlag";

/* Subheader for petition blocker flags */

const BlockerRow = styled.div`
  margin: 0 0 -2rem -1.5rem;
  color: #818da4;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap; 
  align-items: center;
  justify-content: flex-start 
  width: 100%;
`;

export default function PetitionActions(props) {
  const { petitionId, blockers } = props;

  const renderBlocker = key => (
    <BlockerFlag
      petitionId={petitionId}
      label={key}
      isActive={blockers[key]}
      key={key}
    />
  );

  return (
    <BlockerRow>
      {Object.keys(blockers).map(key => renderBlocker(key))}
    </BlockerRow>
  );
}

PetitionActions.propTypes = {
  blockers: PropTypes.object
};
