import { render, within, fireEvent, waitFor } from "@testing-library/react";
import PetitionIndexPage from "./PetitionIndexPage";
import { mockPetitions } from "../../testHelpers/petitionIndexMocks";
import { mockUser } from "../../testHelpers/userMocks";

describe("The PetitionIndexPage component", () => {
  // fetch user for common nav
  beforeEach(() => {
    fetch.once(JSON.stringify(mockUser));
  });

  afterEach(() => fetch.resetMocks());

  it("renders without crashing", async () => {
    const { container } = render(<PetitionIndexPage />);
    await waitFor(() => expect(container));
  });

  it("displays a list of petitions separated by assignee status", async () => {
    const { getByTestId } = render(
      <PetitionIndexPage petitions={mockPetitions} />
    );

    await waitFor(() => {
      within(getByTestId("Assigned Petitions")).getByText("Ned Flanders");
      within(getByTestId("Assigned Petitions")).getByText("Marge Simpson");
      within(getByTestId("Assigned Petitions")).getByText("Montgomery Burns");

      within(getByTestId("Unassigned Petitions")).getByText("Barney Gumble");
      within(getByTestId("Unassigned Petitions")).getByText("Homer Simpson");
      expect(getByTestId("Unassigned Petitions")).not.toHaveTextContent(
        "Seymour Skinner"
      );

      expect(getByTestId("Pending Payment")).not.toHaveTextContent(
        "Seymour Skinner"
      );

      within(getByTestId("Archived Petitions")).getByText("Seymour Skinner");
    });
  });

  it("sorts the petitions within a section by 'last updated' by default", async () => {
    const { getByTestId } = render(
      <PetitionIndexPage petitions={mockPetitions} />
    );

    const assignedPetitionRows = within(
      getByTestId("Assigned Petitions")
    ).getAllByTestId("petition-row");

    await waitFor(() =>
      within(assignedPetitionRows[0]).getByText("Bart Simpson")
    );

    const unassignedPetitionRows = within(
      getByTestId("Unassigned Petitions")
    ).getAllByTestId("petition-row");

    await waitFor(() =>
      within(unassignedPetitionRows[0]).getByText("Homer Simpson")
    );

    const archivedPetitionRows = within(
      getByTestId("Archived Petitions")
    ).getAllByTestId("petition-row");
    await waitFor(() =>
      within(archivedPetitionRows[0]).getByText("Seymour Skinner")
    );
  });

  it(`sorts the petitions chronologically in ascending order when clicking on 
      date created and in descending order on a second click`, async () => {
    const { getByTestId, getAllByTestId } = render(
      <PetitionIndexPage petitions={mockPetitions} />
    );

    fireEvent.click(getAllByTestId("date created")[0]);

    const assignedPetitionRows = within(
      getByTestId("Assigned Petitions")
    ).getAllByTestId("petition-row");

    await waitFor(() =>
      within(assignedPetitionRows[0]).getByText("Marge Simpson")
    );

    fireEvent.click(getAllByTestId("date created")[0]);

    const newAssignedPetitionRows = within(
      getByTestId("Assigned Petitions")
    ).getAllByTestId("petition-row");
    await waitFor(() =>
      within(newAssignedPetitionRows[0]).getByText("Apu Nahasapeemapetilon")
    );
  });

  it("sorts the petitions alphabetically by beneficiary name when clicking on 'petition'", async () => {
    const { getByTestId, getAllByTestId } = render(
      <PetitionIndexPage petitions={mockPetitions} />
    );

    fireEvent.click(getAllByTestId("petition")[0]);
    const assignedPetitionRows = within(
      getByTestId("Assigned Petitions")
    ).getAllByTestId("petition-row");
    await waitFor(() =>
      within(assignedPetitionRows[0]).getByText("Apu Nahasapeemapetilon")
    );

    fireEvent.click(getAllByTestId("petition")[0]);
    const newAssignedPetitionRows = within(
      getByTestId("Assigned Petitions")
    ).getAllByTestId("petition-row");
    await waitFor(() =>
      within(newAssignedPetitionRows[0]).getByText("Ned Flanders")
    );
  });

  it("sorts by visa type when clicking 'visa type'", async () => {
    const { getByTestId, getAllByTestId } = render(
      <PetitionIndexPage petitions={mockPetitions} />
    );

    fireEvent.click(getAllByTestId("visa type")[0]);
    const assignedPetitionRows = within(
      getByTestId("Assigned Petitions")
    ).getAllByTestId("petition-row");
    await waitFor(() => within(assignedPetitionRows[0]).getByText("EB-2"));
  });

  it("displays the appropriate search results when typing in the search bar", async () => {
    const { getByTestId, getAllByTestId } = render(
      <PetitionIndexPage petitions={mockPetitions} />
    );

    fireEvent.change(getByTestId("petition-index-searchbar"), {
      target: { value: "Barney Gumble" }
    });

    await waitFor(() =>
      within(getAllByTestId("petition-row")[0]).getByText("Barney Gumble")
    );
  });
});
