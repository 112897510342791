import React from "react";

/* List of links to html sections for side navs */

export default function SectionsMenu(props) {
  const { sections = [] } = props;

  const handleSectionClick = sectionName => {
    window.location = `#${sectionName}`;
    window.scrollBy(0, -70);
  };

  const sectionLinks = sections.map(section => (
    <a
      key={section.name}
      href={`#${section.name.toLowerCase().replace(/\s/g, "")}`}
      className="button text-button section-menu"
      data-turbolinks="false"
      onClick={() => handleSectionClick(section.name)}
    >
      {section.name}
    </a>
  ));

  return <div className="nav-item section-list">{sectionLinks}</div>;
}
