/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import useContextMenu from "react-use-context-menu";
import { useAlert } from "react-alert";
import { formHeaders } from "../../../utils/form";
import { IndexItem } from "../../Shared/IndexItem/IndexItem";
import { IndexRow } from "../../Shared/IndexRow/IndexRow";
import { ContextMenu } from "../../Shared/ContextMenu/ContextMenu";

export default function PetitionRow(props) {
  const popup = useAlert();

  const { petition, setPetitionsState } = props;

  const [
    bindMenu,
    bindMenuItems,
    useContextTrigger,
    { setVisible: setContextMenuVisible }
  ] = useContextMenu();
  const [bindTrigger] = useContextTrigger({});

  const handleArchive = async unarchive => {
    // Pass 'unarchive' or true to this function to unarchive

    try {
      const url = unarchive
        ? Routes.unarchive_petition_path(petition.id)
        : Routes.archive_petition_path(petition.id);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show(response.error.message, { type: "error" });
      }

      if (response.ok) {
        // API returns the petition object and can be inserted into the state
        // via find and replace with map
        const json = await response.json();
        setPetitionsState(prevState =>
          prevState.map(p => (p.id === petition.id ? json : p))
        );
        setContextMenuVisible(false);
        popup.show(
          `${
            unarchive
              ? "Your petition has been unarchived"
              : "Your petition has been archived"
          }`,
          { type: "success" }
        );
      }
    } catch (error) {
      popup.show("Something went wrong. Please contact Legalpad Engineering.", {
        type: "error"
      });
    }
  };

  function PetitionContextMenu() {
    return (
      <ContextMenu {...bindMenu}>
        {/* eslint-disable-next-line no-undef */}
        <a
          {...bindMenuItems}
          href={Routes.petition_path(petition.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open in new tab
        </a>
        <hr />
        {/* eslint-disable-next-line no-undef */}
        <a {...bindMenuItems} href={Routes.edit_petition_path(petition.id)}>
          Edit
        </a>
        <hr />
        {!petition.archived && (
          <a
            onClick={() =>
              window.confirm(
                "Are you sure you want to archive this petition?"
              ) && handleArchive()
            }
          >
            Archive
          </a>
        )}
        {petition.archived && (
          <a onClick={() => handleArchive("unarchive")}>Unarchive</a>
        )}
      </ContextMenu>
    );
  }

  return (
    <div data-testid="petition-row">
      <PetitionContextMenu />
      <IndexRow
        as="div"
        clickable
        {...bindTrigger}
        key={petition.id}
        id={`petition-row-${petition.id}`}
        onClick={() => (window.location = Routes.petition_path(petition.id))}
      >
        <div>
          <IndexItem bold marginBottom>
            {petition.beneficiaryName}
          </IndexItem>
          <IndexItem>
            {(petition.petitioner.legalName ||
              petition.petitioner.displayName) ??
              petition.petitioner}
          </IndexItem>
        </div>
        <IndexItem grayDark>{petition.visaType}</IndexItem>
        <IndexItem grayDark>{petition.createdAt}</IndexItem>
        <IndexItem>{petition.assignee ?? <a>Assign</a>}</IndexItem>
        <IndexItem grayDark>{petition.updatedAt[1]}</IndexItem>
      </IndexRow>
    </div>
  );
}
