/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "../Shared/AlertTemplate";
import SectionsDropdowns from "../Shared/SectionDropdowns/SectionDropdowns";
import PetitionsTable from "./PetitionsTable/PetitionsTable";
import SideNav from "./SideNav/SideNav";
import CommonNav from "../Shared/CommonNav/CommonNav";
import SearchBar from "../Shared/SearchBar/SearchBar";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
};
export default function PetitionIndexPage(props) {
  const { currentUser = {}, petitions = [] } = props;

  const [petitionsState, setPetitionsState] = useState(petitions);

  const [caseState, setCaseState] = useState({
    assignedCases: [],
    unassignedCases: [],
    pendingCases: [],
    archivedCases: []
  });

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const filteredPetitions = petitionsState.filter(petition => {
      const searchables = [
        petition.beneficiaryName,
        petition.petitioner.legalName,
        petition.petitioner.displayName,
        petition.assignee,
        petition.visaType,
        // So you can search 'EB2 or O1'
        petition.visaType.replace(/-/g, "")
      ]
        .toString()
        .toLowerCase();

      return searchables.includes(searchString.trim().toLowerCase());
    });

    const archivedCases = filteredPetitions.filter(
      petition => petition.archived === true
    );
    const liveCases = filteredPetitions.filter(
      petition => petition.archived === false
    );

    setCaseState({
      archivedCases,
      assignedCases: liveCases.filter(
        petition => petition.assignee !== null && petition.state === "active"
      ),
      unassignedCases: liveCases.filter(
        petition => petition.assignee === null && petition.state === "active"
      ),
      pendingCases: liveCases.filter(petition => petition.state === "pending")
    });
  }, [searchString, petitionsState]);

  const sections = [
    {
      name: "Assigned Petitions",
      content: (
        <PetitionsTable
          groupName="Assigned Petitions"
          petitionGroup={caseState.assignedCases}
          setPetitionsState={setPetitionsState}
          searchString={searchString}
          maxPerPage={15}
        />
      )
    },
    {
      name: "Unassigned Petitions",
      content: (
        <PetitionsTable
          groupName="Unassigned Petitions"
          petitionGroup={caseState.unassignedCases}
          setPetitionsState={setPetitionsState}
          searchString={searchString}
          maxPerPage={10}
        />
      )
    },
    {
      name: "Pending Payment",
      content: (
        <PetitionsTable
          groupName="Pending Payment Petitions"
          petitionGroup={caseState.pendingCases}
          setPetitionsState={setPetitionsState}
          searchString={searchString}
          maxPerPage={10}
        />
      )
    },
    {
      name: "Archived Petitions",
      content: (
        <PetitionsTable
          groupName="Archived Petitions"
          petitionGroup={caseState.archivedCases}
          setPetitionsState={setPetitionsState}
          searchString={searchString}
          maxPerPage={10}
        />
      )
    }
  ];

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <CommonNav />
      <SideNav sections={sections} engAccess={currentUser.engAccess} />
      <main>
        <SearchBar
          id="petition-index-searchbar"
          dataTestId="petition-index-searchbar"
          placeholder="Search by beneficiary, petitioner, assignee, or visa type"
          searchStringValue={searchString}
          setSearchStringEvent={setSearchString}
        />
        <SectionsDropdowns sections={sections} />
      </main>
    </AlertProvider>
  );
}

PetitionIndexPage.propTypes = {
  currentUser: PropTypes.object,
  petitions: PropTypes.array
};
