import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// eslint-disable-next-line import/no-unresolved
import IntakeUploadArt from "images/intakes/company_intake/document_upload.png";
import Dropzone from "react-dropzone-uploader";
import { ImCancelCircle } from "react-icons/im";
import Loader from "react-loader-spinner";
import { useAlert, Provider as AlertProvider, positions } from "react-alert";
import { authenticityToken } from "../../utils/form";
import { grayDarker, red } from "../../styles/colors";
import AlertTemplate from "../Shared/AlertTemplate";
import { Button } from "../Shared/Button/Button";
import request from "../../services/request";

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 3rem;
  text-align: center;
  height: 100%;
  padding: 3rem 5rem;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;

const PreviewContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;

  svg {
    :hover {
      cursor: pointer;
      color: ${red};
    }
  }

  img {
    border-radius: 4px;
  }

  .filename {
    width: 12rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
`;

function FilePreview(props) {
  const { meta, fileWithMeta } = props;
  const { name, percent, status, previewUrl } = meta;
  return (
    <PreviewContainer>
      <div style={{ width: "60px" }}>
        <img src={previewUrl} height={50} alt="upload-preview" />
      </div>
      <p className="filename">{name}</p>
      <progress
        max={100}
        value={
          status === "done" || status === "headers_received" ? 100 : percent
        }
      />
      <ImCancelCircle onClick={() => fileWithMeta.remove()} />
    </PreviewContainer>
  );
}

function SubmitButton(props) {
  const { onSubmit, disabled } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  if (loading)
    return (
      <Loader
        type="ThreeDots"
        color="#2680FA"
        height={18}
        width={64}
        style={{ marginBottom: "3rem" }}
      />
    );

  return (
    <Button
      disabled={disabled}
      type="button"
      style={{ marginBottom: "1rem" }}
      onClick={() => handleSubmit()}
    >
      Submit
    </Button>
  );
}

function InputContent({ files, extra, isMultiple }) {
  return extra.reject ? (
    "Image and PDF files only"
  ) : (
    <div style={{ display: "block" }}>
      <p>Drag {isMultiple ? "Files" : "File"} or Click to Browse</p>
      <p style={{ color: grayDarker }}>Accepted File Types: JPG, PNG, PDF</p>
      <p style={{ color: grayDarker }}>Max File Size: 25MB</p>
    </div>
  );
}

function FileUploader({ url, isMultiple, currentRecord }) {
  const popup = useAlert();

  // specify upload params and url for your files

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    body.append("currentRecord", currentRecord);
    return { url, headers: { "X-CSRF-Token": authenticityToken() }, body };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta, status) => {
    const { xhr } = fileWithMeta;
    if (status === "done") {
      const response = JSON.parse(xhr.response);
      fileWithMeta.itemId = response.id;
    }

    if (status === "error_file_size") {
      popup.show("File exceeds 25mb limit", { type: "error" });
      fileWithMeta.remove();
    }

    if (status === "removed") {
      if (fileWithMeta.itemId) {
        request(`${url}/${fileWithMeta.itemId}`, { method: "DELETE" });
      }
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (_, allFiles) => {
    let metadataUrl = `${url}/metadata?`;
    allFiles.forEach(file => {
      metadataUrl += `upload_item_ids[]=${file.itemId}&`;
    });
    window.location = metadataUrl;
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*,application/pdf"
      maxSizeBytes={2.5e7}
      multiple={isMultiple}
      PreviewComponent={FilePreview}
      SubmitButtonComponent={SubmitButton}
      inputContent={(files, extra) => (
        <InputContent files={files} extra={extra} isMultiple={isMultiple} />
      )}
      submitButtonDisabled={files =>
        files.some(f =>
          ["preparing", "getting_upload_params", "uploading"].includes(
            f.meta.status
          )
        )
      }
      styles={{
        dropzone: {
          minHeight: 400,
          minWidth: 600
        },
        dropzoneReject: { borderColor: red, backgroundColor: "#DAA" },
        inputLabel: (files, extra) => (extra.reject ? { color: red } : {})
      }}
    />
  );
}

export default function IntakeItemUpload({
  label,
  url,
  isMultiple,
  currentRecord,
  subHeader
}) {
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <Container className="new-ui">
        <div className="centered">
          <img
            src={IntakeUploadArt}
            alt="intake-upload-art"
            style={{ height: "450px", margin: "0 auto 1rem" }}
          />
        </div>

        <div className="centered">
          <div>
            <h1 style={{ marginBottom: "35px" }}>
              Upload <strong>{label}</strong> here
            </h1>
            { subHeader && <p style={{ fontSize: "22px" }}> {subHeader} </p> }
            <FileUploader
              url={url}
              isMultiple={isMultiple}
              currentRecord={currentRecord}
            />
          </div>
        </div>
      </Container>
    </AlertProvider>
  );
}
