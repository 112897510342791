import { render, fireEvent, waitFor } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import ContactCard from "./ContactCard";
import AlertTemplate from "../Shared/AlertTemplate";

describe("The ContactCard component", () => {
  window.alert = alert => {
    // eslint-disable-next-line no-console
    console.error(alert);
  };
  it("renders without crashing", () => {
    const { container } = render(
      <AlertProvider template={AlertTemplate}>
        <ContactCard />
      </AlertProvider>
    );
    expect(container);
    expect(fetch.mock.calls.length).toEqual(0);
  });

  it("does not render the edit button for contacts besides attorneys", () => {
    const { queryByText } = render(
      <AlertProvider template={AlertTemplate}>
        <ContactCard />
      </AlertProvider>
    );
    expect(queryByText("Change")).not.toBeInTheDocument();
  });

  it("renders the edit button for attorney contacts", () => {
    const { getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <ContactCard
          contact={{
            role: "Attorney",
            name: "Homer Simpson",
            isAttorney: true
          }}
        />
      </AlertProvider>
    );
    expect(getByText("Change"));
  });

  it("displays the dropdown selector on edit click", () => {
    const { getByText, getByTestId } = render(
      <AlertProvider template={AlertTemplate}>
        <ContactCard
          contact={{
            role: "Attorney",
            name: "Homer Simpson",
            isAttorney: true
          }}
        />
      </AlertProvider>
    );
    fireEvent.click(getByText("Change"));
    expect(getByTestId("attorney-select"));
  });

  it("allows the user to update the attorney", async () => {
    fetch.once(
      JSON.stringify({
        role: "Attorney",
        name: "Bart Simpson",
        id: "123",
        email: "bart@example.com",
        phone: "11235813"
      })
    );
    const { getByText, getByTestId } = render(
      <AlertProvider template={AlertTemplate}>
        <ContactCard
          contact={{
            role: "Attorney",
            name: "Mr. Burns",
            id: "789",
            isAttorney: true
          }}
          attorneyOptions={[
            { value: "123", label: "Bart Simpson" },
            { value: "456", label: "Ned Flanders" },
            { value: "789", label: "Mr. Burns" }
          ]}
        />
      </AlertProvider>
    );
    fireEvent.click(getByText("Change"));
    const attorneySelectInput = getByTestId("attorney-select").querySelector(
      "input"
    );
    fireEvent.focus(attorneySelectInput);
    fireEvent.keyDown(attorneySelectInput, {
      key: "ArrowDown",
      code: 40
    });
    fireEvent.keyDown(attorneySelectInput, {
      key: "Enter",
      code: 13
    });
    await waitFor(() => {
      getByText("Bart Simpson");
      getByText("bart@example.com");
      getByText("11235813");
    });
  });
});
