import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

export default function UscisCaseAware(props) {
  const { caseAwareStatusState } = props;

  // Update the last updated time ago in words every 30 seconds for websockets setup
  // const [timeAgoInWords, setTimeAgoInWords] = useState(
  //   moment(caseAwareStatusState.caseawareUpdatedAt).fromNow()
  // );

  // Be sure to replace the last updated variable below with `timeAgoInWords`

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeAgoInWords(
  //       moment(caseAwareStatusState.caseawareUpdatedAt).fromNow()
  //     );
  //   }, 30000);
  //   return () => clearInterval(interval);
  // }, []);

  const caseAwareEmoji = caseAwareStatus => {
    if (caseAwareStatus === null || caseAwareStatus === undefined) return;
    const statusArray = caseAwareStatus.toLowerCase().split(" ");
    switch (true) {
      case statusArray.includes("transferred"):
        return "🤔";
      case statusArray.includes("approved"):
        return "🥳";
      case statusArray.includes("denied"):
        return "😔";
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ gridColumn: 2, marginBottom: "1.5rem" }}>
        <h4>USCIS CaseAware</h4>
        {caseAwareStatusState.caseawareUpdatedAt && (
          <small>
            Last update{" "}
            {moment(caseAwareStatusState.caseawareUpdatedAt).fromNow()}
          </small>
        )}
      </div>
      <div style={{ gridColumn: "2/4", maxWidth: "40rem" }}>
        <h6>
          {caseAwareStatusState.caseawareStatus}{" "}
          <span>{caseAwareEmoji(caseAwareStatusState.caseawareStatus)}</span>
        </h6>
        <p>{caseAwareStatusState.caseawareDetails}</p>
      </div>
    </>
  );
}

UscisCaseAware.propTypes = {
  caseAwareStatusState: PropTypes.object
};
