/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import request from "../../services/request";
import { grayDarkest, blueLightest, blue } from "../../styles/colors";
import { formHeaders } from "../../utils/form";

const SplitButton = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${blueLightest};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  align-items: center;
  cursor: pointer;
  i {
    color: ${blue};
  }
`;

export default function IntakeRow(props) {
  const { intake, petitionerName, userOptions = [] } = props;
  const submitted = !!intake.submittedAt;
  const [isDisabled, setDisabled] = useState(intake.archivedAt);

  const rowClass = submitted
    ? "finished"
    : isDisabled
    ? "disabled"
    : "missing-variables";

  const defaultOption = userOptions.find(
    option => option.value === intake.userId
  );

  const [isSelectingRecipient, setIsSelectingRecipient] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedRecipient, setSelectedRecipient] = useState(defaultOption);

  const selectedRecipientName =
    !selectedRecipient && intake.userId
      ? "Person Not in Org"
      : selectedRecipient.label;

  const handleArchiveIntake = async unarchive => {
    try {
      const url = unarchive
        ? Routes.unarchive_intake_path(intake.id)
        : Routes.archive_intake_path(intake.id);
      await request(url, { method: "POST" });
      if (unarchive) return setDisabled(false);
      setDisabled(true);
    } catch (error) {
      alert(error);
    }
  };

  const handleRecipientSelect = async selectedOption => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      const url = Routes.intake_path(intake.id);
      const requestBody = {
        user_id: selectedOption.value
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        alert("Intake could not be reassigned.");
        setLoading(false);
      }

      if (response.ok) {
        setSelectedRecipient(selectedOption);
        setTimeout(() => {
          setLoading(false);
          setIsSelectingRecipient(false);
        }, 600);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
      setIsSelectingRecipient(false);
    }
  };

  const copyMenuRef = useRef(null);

  const renderRecipientSelect = () => (
    <>
      {!isSelectingRecipient && (
        <SplitButton
          onClick={() => setIsSelectingRecipient(true)}
          onKeyDown={() => setIsSelectingRecipient(true)}
        >
          <a role="button" style={{ fontWeight: 300, margin: 0 }} tabIndex={0}>
            {selectedRecipientName}
          </a>
          <i
            style={{ fontSize: 10 }}
            className="fa fa-chevron-down"
            aria-hidden="true"
          ></i>
        </SplitButton>
      )}
      {isSelectingRecipient && (
        <div
          ref={copyMenuRef}
          data-testid="ml-user-select"
          style={{
            width: "auto",
            color: grayDarkest,
            fontSize: "12px"
          }}
        >
          <Select
            data-testid="select"
            value={selectedRecipient}
            options={userOptions}
            onChange={handleRecipientSelect}
            className="recipient-user-select"
            classNamePrefix="recipient-user"
            placeholder="Select recipient..."
            openMenuOnFocus
            autoFocus
          />
        </div>
      )}
    </>
  );

  useEffect(() => {
    const handleClickOutside = e => {
      if (copyMenuRef.current && !copyMenuRef.current.contains(e.target)) {
        setIsSelectingRecipient(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [copyMenuRef]);

  return (
    <div
      className={`document-row intake ${rowClass}`}
      data-testid={`intake-row-${intake.id}`}
    >
      <div className="info-icon">
        <div className="icon" />
      </div>
      <div className="title intake-title">{intake.name}</div>

      <div className="info-column">{renderRecipientSelect()}</div>

      <div className="info-column">
        <div className="value">{petitionerName}</div>
      </div>

      <div className="submitted-at-column">
        {submitted && (
          <div className="status">
            Submitted <br /> {intake.submittedAt}
          </div>
        )}
      </div>

      <div className="actions-container">
        {!isDisabled ? (
          <button
            type="button"
            className="small secondary button text-button button-font-size destructive"
            onClick={() => handleArchiveIntake()}
          >
            Disable
          </button>
        ) : (
          <button
            type="button"
            className="small secondary button text-button button-font-size"
            onClick={() => handleArchiveIntake(true)}
          >
            Enable
          </button>
        )}
        <a
          type="button"
          className="small secondary button text-button button-font-size"
          href={`${Routes.root_url({ subdomain: "" })}/intakes/${
            intake.id
          }/sections/review/steps/details`}
        >
          View
        </a>
      </div>
    </div>
  );
}
