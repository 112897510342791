import React from "react";
import MaskedInput from "react-text-mask";

const mask = [
  "+",
  "1",
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export default class UsaPhoneNumberField extends React.Component {
  _handleValueChange(e) {
    this.hiddenField.value = this._cleanValue(e.target.value);
  }

  // eslint-disable-next-line class-methods-use-this
  _cleanValue(value) {
    if (value) {
      return value.replace(/_/g, "");
    }
    return "";
  }

  render() {
    const { name, value, label, placeholder } = this.props;
    return (
      <React.Fragment>
        <input
          type="hidden"
          name={name}
          ref={el => (this.hiddenField = el)}
          defaultValue={value}
        />
        <MaskedInput
          mask={mask}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={e => this._handleValueChange(e)}
        />
        {label && <label>{label}</label>}
      </React.Fragment>
    );
  }
}
