import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

const StatusItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &&& {
    a,
    p {
      margin: 1rem 0;
    }
    a {
      margin-left: 1rem;
    }
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: auto 20rem;
  grid-column-gap: 0.5rem;
`;

const Input = styled.input`
  padding: 1rem;
  width: 18rem;
  border-radius: 4px;
  border: 1px solid #b9bfcb;
`;

export default function FormItem(props) {
  const {
    type,
    name,
    placeholder,
    helperText,
    value,
    caseStatusAttribute,
    handleChange,
    handleSubmit
  } = props;
  const [formVisible, setFormVisible] = useState(!caseStatusAttribute);

  const handleBlur = async () => {
    await handleSubmit();
    if (formVisible && caseStatusAttribute) {
      setFormVisible(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBlur();
    }
  };

  const displayValue = v => {
    if (name !== "caseNumber" && v !== "") {
      return moment(v).format("MM/DD/YYYY");
    }
    return v;
  };

  useEffect(() => {
    setFormVisible(!caseStatusAttribute);
  }, [caseStatusAttribute]);

  return formVisible ? (
    <>
      <InputContainer>
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={e => handleChange(e)}
          onBlur={() => handleBlur()}
          onKeyDown={e => handleKeyDown(e)}
          autoFocus
          data-testid={`${name}-input`}
        ></Input>
        <p className="press-enter">
          Press <strong>Enter </strong>to save
        </p>
      </InputContainer>
      <p className="helper">{helperText}</p>
    </>
  ) : (
    <>
      <StatusItem>
        <p>{displayValue(value)}</p>
        <a
          tabIndex={0}
          role="button"
          onClick={() => setFormVisible(true)}
          onKeyDown={() => setFormVisible(true)}
          data-testid={`${name}-edit-button`}
        >
          Edit
        </a>
      </StatusItem>
    </>
  );
}
