import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { formHeaders } from "../../../../../utils/form";
import DeleteConfirmModal from "../../../../Shared/DeleteConfirmModal/DeleteConfirmModal";
import NoteEdit from "../../../../NotesConsole/NoteEdit/NoteEdit";
import { grayLight } from "../../../../../styles/colors";

const Row = styled.div`
  border-top: ${props =>
    props.rowSelected ? "1px solid #2680fa" : `1px solid ${grayLight}`};
  &:hover {
    border-top: ${props =>
      props.readOnly ? "1px solid #2680fa" : "2px solid #2680fa"};
  }
  padding: 1rem 0;
  display: flex;
`;

const MetaData = styled.div`
  &&& {
    font-size: 14px;
  }
  width: 9rem;
  margin-right: 3rem;
`;

const DeleteButton = styled.div`
  flex-grow: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

export default function NoteRow(props) {
  const { note, notes, setNotes, id } = props;

  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);

  const handleDelete = async noteId => {
    try {
      const url = Routes.note_url(noteId);

      const response = await fetch(url, {
        method: "DELETE",
        headers: formHeaders()
      });

      if (!response.ok) {
        alert("Something went wrong.");
      }

      if (response.ok) {
        // const json = await response.json();
        setNotes(notes.filter(n => n.id !== noteId));
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Row id={id} rowSelected={rowSelected} readOnly={note.read_only}>
      <MetaData>
        <strong>{note.author_name}</strong>
        <div>
          {note.source &&
            note.source
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, string => string.toUpperCase())}
        </div>
        <div style={{ color: "#818DA4" }}>
          {moment(note.created_at).format("MMM Do, YYYY")}
        </div>
      </MetaData>
      <div style={{ flexGrow: 3 }}>
        <NoteEdit
          setRowSelected={setRowSelected}
          note={note}
          notes={notes}
          setNotes={setNotes}
          handleDelete={handleDelete}
        />
      </div>
      {!note.read_only && (
        <DeleteButton>
          {!deleteConfirmVisible && (
            <button
              id={`delete-button-${note.id}`}
              className="button text-button small secondary"
              type="button"
              onClick={() => setDeleteConfirmVisible(true)}
            >
              Delete
            </button>
          )}
          {deleteConfirmVisible && (
            <DeleteConfirmModal
              setDeleteConfirmVisible={setDeleteConfirmVisible}
              handleDelete={handleDelete}
              itemId={note.id}
            />
          )}
        </DeleteButton>
      )}
    </Row>
  );
}
