/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { formHeaders } from "../../../../utils/form";
import { IndexRow } from "../../../Shared/IndexRow/IndexRow";

export default function TemplatesRow(props) {
  const { petitionId, template } = props;

  const [loading, setLoading] = useState(false);

  const popup = useAlert();

  const handleGenerate = async () => {
    setLoading(true);

    try {
      const params = {
        petition_id: petitionId,
        template_id: template.id
      };

      const url = Routes.webmerge_generate_url(params);

      const requestBody = {};

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const json = await response.json();

        window.location.reload();
        return false;
      }
      const errorMessage =
        "Something went wrong when generating the document. Please check the Webmerge template for errors or contact support.";
      popup.show(errorMessage, { type: "error" });
    } catch (error) {
      popup.show(error, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IndexRow as="div" className="template-row">
        <a
          href={Routes.petition_template_url(petitionId, template.id)}
          style={{ margin: 0 }}
        >
          {template.name}
        </a>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {loading && (
            <Loader type="ThreeDots" color="#2680FA" height={18} width={64} />
          )}
          {!loading && (
            <button
              type="button"
              className="small secondary button text-button button-font-size"
              onClick={() => handleGenerate()}
              style={{ width: "12rem" }}
            >
              Generate
            </button>
          )}
        </div>
      </IndexRow>
    </>
  );
}

TemplatesRow.propTypes = {
  petitionId: PropTypes.string,
  template: PropTypes.object
};
