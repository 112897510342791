import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import { formHeaders } from "../../../../utils/form";
import NotesTable from "./NotesTable/NotesTable";
import { fetchReducer } from "../../../../reducers/fetchReducer";

export default function NotesDropdown(props) {
  const { notable = {}, notableType = "Petition", errors, setErrors } = props;
  const [notes, setNotes] = useState([]);
  const [fetchState, dispatch] = useReducer(fetchReducer, {
    success: false,
    loading: false,
    response: null
  });

  useEffect(() => {
    const fetchNotes = async (type, notableId) => {
      dispatch({ type: "FETCH_STARTED" });
      try {
        const url = Routes.notes_url({
          notable_type: type,
          notable_id: notableId
        });

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_ERROR", popupMessage: json.message });
          setErrors([...errors, { message: "Notes could not be fetched" }]);
        }

        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_COMPLETE", response: json });
          setNotes(json.notes);
        }
      } catch (error) {
        dispatch({ type: "FETCH_ERROR", popupMessage: error });
        setErrors([...errors, { message: "Notes could not be fetched" }]);
      }
    };
    fetchNotes(notableType, notable.id);
  }, [notable.id, notableType]);

  if (fetchState.loading)
    return <Loader type="ThreeDots" color="#2680FA" height={32} width={32} />;

  if (!fetchState.loading)
    return (
      <NotesTable
        notes={notes}
        setNotes={setNotes}
        notable={notable}
        notableType={notableType}
      />
    );
}

NotesDropdown.propTypes = {
  notable: PropTypes.object,
  notableType: PropTypes.string
};
