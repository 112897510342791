import React from "react";
import styled from "styled-components";

const Table = styled.section`
  table {
    width: 100%;
    table-layout: fixed;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .tbl-header {
    background-color: lightgray;
  }
  .tbl-content {
    height: 420px;
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid lightgray;
  }
  th {
    padding: 10px 5px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    color: black;
    text-transform: uppercase;
  }
  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 12px;
    color: black;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
`;

export default function PetitionStatus(props) {
  const { petitions } = props;

  const petitionUrl = petitionId => Routes.petition_templates_path(petitionId);

  const tableHead = (
    <tr>
      {Object.keys(petitions[0]).map(k => (
        <th key={k}>{k}</th>
      ))}
    </tr>
  );

  const tableBody = petitions.map(p => (
    <tr key={p.Petition.id}>
      {Object.keys(p).map(key => {
        if (key === "Full Name" || key === "Tasks Completed") {
          return <td key={key}>{p[key]}</td>;
        }

        if (key === "Petition") {
          return (
            <td key={key}>
              <a href={petitionUrl(p.Petition.id)}>{p.Petition.title}</a>
            </td>
          );
        }

        return (
          <td key={key}>
            {p[key] !== null ? (
              <i
                className="fas fa-check"
                style={{ color: "#41CD7E" }}
                title={p[key]}
              />
            ) : (
              ""
            )}
          </td>
        );
      })}
    </tr>
  ));

  return (
    <Table>
      <div className="tbl-header">
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>{tableHead}</thead>
        </table>
      </div>
      <div className="tbl-content">
        <table cellPadding="0" cellSpacing="0" border="0">
          <tbody>{tableBody}</tbody>
        </table>
      </div>
    </Table>
  );
}
