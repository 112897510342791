import React from "react";
import { render } from "@testing-library/react";
import NoteRow from "./NoteRow";

describe("The Note Row component", () => {
  it("renders without crashing", () => {
    const { container } = render(
      <NoteRow
        note={{ text: "Defualt note text", source: "PetitionOverview" }}
      />
    );
    expect(container);
  });
  it("splits up the note source if camelCase", () => {
    const { getByText } = render(
      <NoteRow
        note={{ text: "Defualt note text", source: "PetitionOverview" }}
      />
    );
    expect(getByText("Petition Overview"));
  });
});
