/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from "react";
import MaskedInput from "react-text-mask";
import moment from "moment";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import PropTypes from "prop-types";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy");
const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

export default class DateField extends React.Component {
  handleOnChange(e) {
    const value = this.serverDate(e.target.value);
    const complete = e.target.value.replace("_", "").length === 10;

    this.hiddenField.value = value;

    if (this.props.handleInputChange && complete) {
      this.props.handleInputChange(value);
    }
  }

  serverDate(value) {
    if (value) {
      if (moment(value, "MM/DD/YYYY").isValid()) {
        return moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
      }
      return value;
    }
  }

  displayDate(value) {
    if (value) {
      return moment(value, "YYYY-MM-DD").format("MM/DD/YYYY");
    }
  }

  render() {
    const { name, label, value, placeholder } = this.props;
    return (
      <React.Fragment>
        <input
          type="hidden"
          name={name}
          ref={el => (this.hiddenField = el)}
          defaultValue={this.serverDate(value)}
        />
        <MaskedInput
          mask={mask}
          type="text"
          value={this.displayDate(value)}
          guide
          placeholder={placeholder}
          keepCharPositions
          pipe={autoCorrectedDatePipe}
          onChange={e => this.handleOnChange(e)}
          onBlur={this.props.handleInputBlur}
        />
        {/* Internal label needs to be _after_ the input for CSS to work. */}
        {label && <label>{label}</label>}
      </React.Fragment>
    );
  }
}

DateField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleInputBlur: PropTypes.func
};
