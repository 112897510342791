import { render } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import PetitionRow from "./PetitionRow";
import { mockPetitions } from "../../../testHelpers/petitionIndexMocks";
import AlertTemplate from "../../Shared/AlertTemplate";

describe("The PetitionRow component", () => {
  it("renders without crashing", () => {
    const { container } = render(
      <AlertProvider template={AlertTemplate}>
        <PetitionRow petition={mockPetitions[1]} />
      </AlertProvider>
    );
    expect(container);
  });

  it("displays the petitioner display name if legal name is null", () => {
    const { getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <PetitionRow petition={mockPetitions[1]} />
      </AlertProvider>
    );
    expect(getByText("Duff Beer Company"));
  });

  it("displays the petitioner display name if legal name is an empty string", () => {
    const { getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <PetitionRow
          petition={{
            id: "7b0ac662-e948-58ed-9d96-6bc489c2d1a0",
            beneficiaryName: "Moe Syzslak",
            petitioner: { legalName: "", displayName: "Moe's Tavern" },
            assignee: "Legal Assistant 1",
            visaType: "O-1",
            createdAt: "April 10, 2020",
            updatedAt: ["April 11, 2020", "one day"],
            archived: false
          }}
        />
      </AlertProvider>
    );
    expect(getByText("Moe's Tavern"));
  });
});
