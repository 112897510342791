/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import CommonNav from "../Shared/CommonNav/CommonNav";
import NotesConsole from "../NotesConsole/NotesConsole";

const Container = styled.article`
  border-radius: 4px;
  background: #fff;
  height: fit-content;
  @media only screen and (min-width: 600px) {
    /* min-width: fit-content; */
  }

  header {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    border-radius: 6px 6px 0 0;
    background: linear-gradient(
      90deg,
      rgba(39,239,151,1) 0%,
      rgba(33,186,137,1) 0%,
      rgba(24,224,136,1) 100%
    );
    padding: 1rem;
    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 1.5em;
    }
    a {
      color: white !important;
      font-size: 1em !important;
      &:hover {
        transform: scale(1.02);
      }
    }

    &.missing-info {
      background: linear-gradient(
        90deg,
        rgba(39,239,151,1) 0%,
        rgba(175,14,14,1) 0%,
        rgba(255,115,115,1) 100%
      );
    }
  }
`;

const InfoRow = styled.div`
  &&& {
    margin: 0.8rem 0.8rem 0.8rem 0.8rem;
  }
`;

const Card = styled.div`
  &&& {
    max-width: 420px;
    padding: 0;
  }
`;

export default function PetitionCard(props) {
  debugger;
  const {
    title = null,
    info = [],
    missing
  } = props;

  return (
    <Card className="contact-card">
      <Container>
        <header className={`${missing ? "missing-info" : ""}`}>
          <h3>{title}</h3>
        </header>
      </Container>
      {info.map(value => (
        <InfoRow className="info-row">
        { value[0] && <div className="section">{value[0]}</div> }
        <div className="label">{value[1]}</div>
        <div className="value" dangerouslySetInnerHTML={{ __html: value[2] }}></div>
        </InfoRow>
      ))}
    </Card>
  );
}
