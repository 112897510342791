import React from "react";
import PropTypes from "prop-types";
import PetitionAssigneeSelect from "./PetitionAssigneeSelect/PetitionAssigneeSelect";
import StatusTag from "../Shared/StatusTag/StatusTag";

/* Shared subheader for petition show pages */

export default function SubHeader(props) {
  const {
    type,
    token,
    beneficiary,
    hasDependents,
    petitionerName,
    petitionId,
    assigneeId,
    assigneeOptions,
    dealId
  } = props;

  return (
    <section className="header-titles">
      <div className="type">
        <h6>
          {type} - {token} &bull;{" "}
          {dealId && <a href={`/sales/deals/${dealId}`}>View deal</a>}
        </h6>
      </div>
      <div className="title">
        <a href={Routes.client_path(beneficiary.id)} className="like-text">
          <h1>{beneficiary.name}</h1>
        </a>
      </div>
      <div className="company">
        <p>{petitionerName}</p>
        {hasDependents && <StatusTag color="salmon" text="HAS DEPENDENTS" />}
      </div>
      <br />
      <div className="assignee">
        <p>Assigned to:</p>
        <PetitionAssigneeSelect
          assigneeId={assigneeId}
          assigneeOptions={assigneeOptions}
          petitionId={petitionId}
        />
      </div>
    </section>
  );
}

SubHeader.propTypes = {
  assigneeOptions: PropTypes.array,
  assigneeId: PropTypes.string,
  petitionId: PropTypes.string
};
