import React from "react";
import PropTypes from "prop-types";
import NoteItem from "../NoteItem/NoteItem";

export default function NoteList(props) {
  const { notes = [], setNotes } = props;

  return (
    <div id="note-list" data-testid="note-list">
      {notes.map((note, index) => (
        <NoteItem
          id={`note-item-${index + 1}`}
          key={note.id}
          note={note}
          notes={notes}
          setNotes={setNotes}
        />
      ))}
    </div>
  );
}

NoteList.propTypes = {
  notes: PropTypes.array,
  setNotes: PropTypes.func
};
