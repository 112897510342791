import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import CommonNav from "../../Shared/CommonNav/CommonNav";
import { green, white, grayDark } from "../../../styles/colors";
import { formHeaders } from "../../../utils/form";
import FormItem from "./FormItem";
import UscisDecisionStatusSelect from "./UscisDecisionStatusSelect";
import UscisDocumentUpload from "./UscisDocumentUpload";
import UscisCaseAware from "./UscisCaseAware";

// let webSocketUrl = "ws://localhost:3000/cable";
// Default websocketUrl to localhost, but use the one provided by the `action_cable_meta_tag` if present on page
// This should support dynamic websocket urls for each environment
// const actionCableElements = document.getElementsByName("action-cable-url");
// if (actionCableElements.length > 0) {
//   webSocketUrl = actionCableElements[0].getAttribute("content");
// }

// const cable = new WebSocket(webSocketUrl);

const Container = styled.div`
  height: 100%;
  margin: 0rem 4rem 9rem 4rem;
  &&& {
    p {
      max-width: 80rem;
      font-size: 20px;
    }

    h5 {
      margin: 0 0 0 1rem;
    }
  }
`;

const StatusContainer = styled.div`
  margin: 4rem 0;
  width: auto;
  display: grid;
  grid-template-columns: auto 16rem 20rem;
  grid-template-rows: auto auto;
  column-gap: 1rem;
  &&& {
    h4 {
      font-size: 18px;
      font-weight: 600;
      margin: 1rem 0rem 0rem 0rem;
    }
    p {
      margin: 1rem 1rem 1rem 0;
      font-size: 16px;
      font-weight: 500;

      &.helper {
        font-size: 14px;
        font-weight: 300;
        color: ${grayDark};
      }

      &.press-enter {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`;

const StatusIcon = styled.i`
  margin: 0;
  color: ${props => (props.satisfied ? green : white)};
  grid-column: 1;
`;

export default function UscisStatus(props) {
  const {
    caseStatus = {},
    petitionId,
    beneficiaryName,
    decisionStatusTypes = ["approved", "denied", "rfe"],
    decisionStatusDocument
  } = props;

  const {
    shipmentDate = null,
    receiptDate = null,
    caseNumber = null,
    decisionDate = null,
    decisionStatus = null,
    caseawareStatus = null,
    caseawareDetails = null,
    caseawareUpdatedAt = null,
    caseawareErrors = null
  } = caseStatus;

  const [caseStatusState, setCaseStatusState] = useState({
    shipmentDate,
    receiptDate,
    caseNumber,
    decisionDate,
    decisionStatus,
    caseawareStatus,
    caseawareDetails,
    caseawareUpdatedAt,
    caseawareErrors
  });

  const [formState, setFormState] = useState({
    shipmentDate: shipmentDate ?? "",
    receiptDate: receiptDate ?? "",
    caseNumber: caseNumber ?? "",
    decisionDate: decisionDate ?? "",
    decisionStatus: decisionStatus ?? "",
    dirty: false
  });

  const [caseawareLoading, setCaseawareLoading] = useState(false);

  const [uploadState, setUploadState] = useState(decisionStatusDocument);

  const popup = useAlert();

  const formatDate = date => (date ? moment(date).format("YYYY-MM-DD") : "");

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
      dirty: true
    });
  };

  const caseURL = `https://egov.uscis.gov/casestatus/mycasestatus.do?appReceiptNum=${caseNumber}`;

  const handleSubmit = async isCaseNumberUpdate => {
    if (formState.dirty) {
      if (isCaseNumberUpdate) setCaseawareLoading(true);
      try {
        const url = `${Routes.petition_url(petitionId)}/case_status`;

        const params = {
          case_status: {
            shipment_date: formState.shipmentDate,
            receipt_date: formState.receiptDate,
            case_number: formState.caseNumber,
            decision_date: formState.decisionDate,
            decision_status: formState.decisionStatus
          }
        };

        const response = await fetch(url, {
          method: "PUT",
          body: JSON.stringify(params),
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show("someting went wrong", { type: "error" });
        }

        if (response.ok) {
          const json = await response.json();
          setFormState({ ...formState, dirty: false });
          setCaseStatusState(json);
          if (json.caseawareErrors) {
            json.caseawareErrors.forEach(err =>
              popup.show(err, { type: "error" })
            );
          }
          if (json.caseawareErrors?.length === 0)
            popup.show(
              "Case Aware has successfully updated this case's details from USCIS",
              { type: "success" }
            );
        }
        setCaseawareLoading(false);
      } catch (error) {
        popup.show(error.message, { type: "error" });
        setCaseawareLoading(false);
      }
    }
  };

  const handleUpload = async e => {
    e.preventDefault();

    try {
      const url = Routes.entity_path(caseStatus.id);
      const data = new FormData(e.target.parentElement);
      const response = await fetch(url, {
        method: "PUT",
        body: data
      });

      if (!response.ok) {
        popup.show("Something went wrong.", { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setUploadState(json.fileData.files[0]);
      }
    } catch (error) {
      popup.show(error, { type: "error" });
    }
  };

  const handleArchive = async () => {
    try {
      const url = Routes.archive_petition_path(petitionId);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show("Something went wrong, contact Legalpad engineering.", {
          type: "error"
        });
      }

      if (response.ok) {
        popup.show("Petition has been archived successfully!", {
          type: "success"
        });
      }
    } catch (error) {
      popup.show("Something went wrong, contact Legalpad engineering.", {
        type: "error"
      });
    }
  };

  // cable.onopen = () =>
  //   cable.send(
  //     JSON.stringify({
  //       command: "subscribe",
  //       identifier: JSON.stringify({
  //         channel: "CaseStatusChannel",
  //         case_status: `${caseStatus.id}`
  //       })
  //     })
  //   );

  // eslint-disable-next-line no-console
  // cable.onclose = () => console.log("Web socket closed");

  // useEffect(() => {
  //   cable.onmessage = e => {
  //     // Listen to the data sent from the web socket cable
  //     const parsedMessage = JSON.parse(e.data);
  //     const { message } = parsedMessage;
  //     if (message && message.type === "case_status_job_completed") {
  //       const { data } = message;
  //       setCaseAwareStatusState(data);

  //       if (message.dataChanged) {
  //         popup.show.show(
  //           "CaseAware™ has successfully collected case details from USCIS.  See below",
  //           { type: "success" }
  //         );
  //       }

  //       if (message.errors) {
  //         message.errors.forEach(err => popup.show.show(err, { type: "error" }));
  //       }
  //     }
  //   };
  //   // Close the socket when component dismounts
  //   return () => cable.close();
  // }, []);

  return (
    <>
      <CommonNav
        home={{ text: "Petitions", href: "/petitions" }}
        title={{ text: beneficiaryName, href: `/petitions/${petitionId}` }}
        subTitle={{
          text: "USCIS Status",
          href: `/petitions/${petitionId}/case_status`
        }}
      />

      <Container>
        <main>
          <div>
            <h3>USCIS Status</h3>
            <p style={{ color: grayDark }}>
              Track and update USCIS Decisions for {beneficiaryName}{" "}
            </p>
          </div>

          <StatusContainer>
            <StatusIcon
              satisfied={caseStatusState.shipmentDate}
              className="fas fa-check-circle fa-3x"
            ></StatusIcon>
            <h4 style={{ gridColumn: 2 }}>Shipped to USCIS</h4>
            <div style={{ gridColumn: 3 }}>
              <FormItem
                type="date"
                name="shipmentDate"
                helperText="Date the petition was shipped to USCIS"
                value={formatDate(formState.shipmentDate)}
                caseStatusAttribute={caseStatusState.shipmentDate}
                handleChange={handleChange}
                handleSubmit={() => handleSubmit()}
              />
            </div>
          </StatusContainer>
          <StatusContainer>
            <StatusIcon
              satisfied={caseStatusState.receiptDate}
              className="fas fa-check-circle fa-3x"
            ></StatusIcon>
            <div style={{ gridColumn: 2 }}>
              <h4>Received by USCIS</h4>
            </div>
            <div style={{ gridColumn: 3 }}>
              <FormItem
                type="date"
                name="receiptDate"
                helperText="Date the petition was receipted by USCIS"
                value={formatDate(formState.receiptDate)}
                caseStatusAttribute={caseStatusState.receiptDate}
                handleChange={handleChange}
                handleSubmit={() => handleSubmit()}
              />
            </div>
            <div style={{ gridColumn: "2/4" }}>
              <FormItem
                type="text"
                name="caseNumber"
                value={formState.caseNumber}
                caseStatusAttribute={caseStatusState.caseNumber}
                placeholder="USCIS Case Number"
                handleChange={handleChange}
                handleSubmit={() => handleSubmit(true)}
              />
              {caseStatusState.caseNumber && (
                <a href={caseURL} target="_blank" rel="noopener noreferrer">
                  View USCIS Case Status
                  <i className="fas fa-external-link u_margin-left-1rem" />
                </a>
              )}
            </div>
          </StatusContainer>
          <StatusContainer>
            <StatusIcon
              satisfied={caseStatusState.decisionDate}
              className="fas fa-check-circle fa-3x"
            ></StatusIcon>
            <h4 style={{ gridColumn: 2 }}>USCIS Decision</h4>
            <div style={{ gridColumn: 3 }}>
              <FormItem
                type="date"
                name="decisionDate"
                helperText="Date the USCIS status decision was made"
                value={formatDate(formState.decisionDate)}
                caseStatusAttribute={caseStatusState.decisionDate}
                handleChange={handleChange}
                handleSubmit={() => handleSubmit()}
              />
            </div>
            {caseStatusState.decisionDate && (
              <div style={{ gridColumn: "2/4", maxWidth: "24rem" }}>
                <UscisDecisionStatusSelect
                  petitionId={petitionId}
                  decisionStatusTypes={decisionStatusTypes}
                  handleChange={handleChange}
                  decisionStatusState={formState.decisionStatus}
                  setFormState={setFormState}
                  handleSubmit={() => handleSubmit()}
                />
                <UscisDocumentUpload
                  caseStatusId={caseStatus.id}
                  uploadState={uploadState}
                  setUploadState={setUploadState}
                  handleUpload={handleUpload}
                />
              </div>
            )}
          </StatusContainer>
          <StatusContainer>
            <StatusIcon
              satisfied={caseStatusState.caseawareStatus}
              className="fas fa-check-circle fa-3x"
            />
            {caseawareLoading ? (
              <div>
                <h4>USCIS CaseAware</h4>
                <br />
                <Loader
                  type="ThreeDots"
                  color="#2680FA"
                  height={64}
                  width={64}
                />
              </div>
            ) : (
              <UscisCaseAware caseAwareStatusState={caseStatusState} />
            )}
          </StatusContainer>

          {caseStatus.shipmentDate &&
            caseStatusState.receiptDate &&
            caseStatusState.decisionDate &&
            uploadState && (
              <StatusContainer>
                <StatusIcon satisfied className="fas fa-check-circle fa-3x" />
                <div style={{ width: "36rem" }}>
                  <h4>Boom! Thank you for filling this out.</h4>
                  <p
                    style={{
                      marginTop: "2rem",
                      fontSize: "18px",
                      fontWeight: 400,
                      color: grayDark
                    }}
                  >
                    <a
                      tabIndex={0}
                      role="button"
                      onKeyDown={() => handleArchive()}
                      onClick={() => handleArchive()}
                    >
                      Archive this petition
                    </a>{" "}
                    if you are done working on it for now. You will still be
                    able to access this petition in the future
                  </p>
                </div>
              </StatusContainer>
            )}
        </main>
      </Container>
    </>
  );
}

UscisStatus.propTypes = {
  caseStatus: PropTypes.object,
  petitionId: PropTypes.string,
  beneficiaryName: PropTypes.string,
  decisionStatusTypes: PropTypes.array
};
