import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { formHeaders } from "../../../utils/form";
import { useHotkeys } from "../../../hooks/useHotKeys";

const MarkdownContainer = styled.div`
  &&& {
    margin: 0 0 1rem 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 21px;
    }

    ul {
      list-style: disc;
      padding-left: 2em;
    }

    li {
      margin-bottom: 0;
    }

    blockquote {
      margin-left: 1em;
      padding-left: 1em;
      border-left: 1px solid #ddd;
    }

    pre {
      background-color: #e1e7f0;
      border-radius: 4px;
      padding: 0.5rem;
    }
    code {
      font-family: "Consolas", "Menlo", "Monaco", monospace, serif;
      font-size: 0.9em;
    }
  }
`;

const Input = styled.textarea`
  &&& {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    width: 100%;
    min-height: auto;
    max-height: 50rem;
  }

  &:disabled {
    background-color: #fff;
    color: #3a4861;
  }
`;

export default function NoteEdit(props) {
  const {
    margin,
    note,
    notes,
    setNotes,
    handleDelete,
    setRowSelected = () => {}
  } = props;

  const [currentInputValue, setCurrentInputValue] = useState(note.text);
  const [showInput, setShowInput] = useState(false);

  const autoExpand = element => {
    // Reset field height
    element.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(element);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      element.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    element.style.height = `${height}px`;
  };

  const updatedNotes = (notesArray, updatedNote) =>
    notesArray.map(n => (n.id === updatedNote.id ? updatedNote : n));

  const handleUpdate = async noteId => {
    try {
      const url = Routes.note_url(noteId);

      const params = {
        text: currentInputValue
      };

      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(params),
        headers: formHeaders()
      });

      if (!response.ok) {
        alert("Unable to save this note.");
      }

      if (response.ok) {
        const json = await response.json();
        setNotes(updatedNotes(notes, json));
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleInputChange = e => {
    setCurrentInputValue(e.target.value);
    autoExpand(e.target);
  };

  const handleFocus = e => {
    setRowSelected(true);
    autoExpand(e.target);
  };

  const handleBlur = () => {
    if (!currentInputValue) {
      handleDelete(note.id);
    } else {
      handleUpdate(note.id);
    }
    setRowSelected(false);
    setShowInput(false);
  };

  const inputRef = useRef(null);

  const handleTab = e => {
    if (e.key === "Tab") {
      e.preventDefault();

      const { selectionStart, selectionEnd } = e.target;
      const newInputValue = `${currentInputValue.substring(
        0,
        selectionStart
      )}  ${currentInputValue.substring(selectionEnd)}`;
      setCurrentInputValue(newInputValue);
      if (inputRef.current) {
        inputRef.current.value = newInputValue;
        // eslint-disable-next-line no-multi-assign
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          selectionStart + 2;
      }
    }
  };

  useEffect(() => {
    if (showInput) {
      autoExpand(inputRef.current);
    }
  }, []);

  useHotkeys(
    "ctrl + enter",
    () => {
      if (document.activeElement === inputRef.current) {
        inputRef.current.blur();
      }
    },
    [inputRef.current, notes]
  );

  if (note.read_only) {
    return (
      <MarkdownContainer id={`edit-note-markdown-${note.id}`}>
        <ReactMarkdown source={currentInputValue} />
      </MarkdownContainer>
    );
  }

  return (
    <>
      {showInput && (
        <Input
          autoFocus
          disabled={!!note.read_only}
          ref={inputRef}
          margin={margin}
          id={`edit-note-input-${note.id}`}
          value={currentInputValue}
          onChange={e => handleInputChange(e)}
          onKeyDown={e => handleTab(e)}
          onFocus={e => handleFocus(e)}
          onBlur={() => handleBlur()}
        />
      )}
      {!showInput && (
        <MarkdownContainer
          id={`edit-note-markdown-${note.id}`}
          onClick={() => {
            setShowInput(true);
          }}
        >
          <ReactMarkdown source={currentInputValue} />
        </MarkdownContainer>
      )}
    </>
  );
}

NoteEdit.propTypes = {
  margin: PropTypes.string,
  note: PropTypes.object,
  notes: PropTypes.array,
  setNotes: PropTypes.func,
  handleDelete: PropTypes.func,
  setRowSelected: PropTypes.func
};
