import { render, waitFor } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import PetitionOverview from "./PetitionOverview";
import AlertTemplate from "../../Shared/AlertTemplate";

describe("PetitionOverview", () => {
  let props;
  window.alert = () => {};

  beforeEach(() => {
    props = {
      type: "P1",
      petitionId: "123",
      petitionGdriveLInk: "gdrive.com",
      petitionerName: "SELAT",
      beneficiary: { role: "benny", name: "Bobby Bouche" },
      petitionRepresentative: { role: "pr", name: "Coach Kline" },
      intakes: [
        {
          id: "1",
          name: "First Intake",
          submittedAt: "Now",
          userId: "RUTA123"
        }
      ],
      intakeUserOptions: [
        {
          value: "RUTA123",
          label: "Rutabega Rabbit"
        },
        {
          value: "CATY456",
          label: "Princess Carolyn"
        }
      ],
      petitionBlockers: {
        "show stopper": true
      },
      noteProps: {
        notable: {},
        notableType: "Petition"
      },
      assigneeOptions: [
        {
          value: "jekyll_ID",
          label: "dr_jekyll@gmail.com"
        },
        {
          value: "hyde_ID",
          label: "mrhyde@yahoo.com"
        }
      ],
      assigneeId: "hyde_ID"
    };
  });

  it("renders without crashing", async () => {
    const container = render(
      <AlertProvider template={AlertTemplate}>
        <PetitionOverview {...props} />
      </AlertProvider>
    );
    await waitFor(() => expect(container));
  });

  it("displays the correct section names", async () => {
    const { getAllByText } = render(
      <AlertProvider template={AlertTemplate}>
        <PetitionOverview {...props} />
      </AlertProvider>
    );
    const ContactsHeaders = getAllByText("Contacts");
    const IntakesHeaders = getAllByText("Intakes");
    await waitFor(() => {
      expect(ContactsHeaders && IntakesHeaders);
      expect(ContactsHeaders.length).toBe(2);
    });
  });
});
