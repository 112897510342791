import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { formHeaders } from "../../../utils/form";
import NoteEdit from "../NoteEdit/NoteEdit";

const NoteContainer = styled.div`
  margin: 1rem;
  padding: 1rem 0;
  box-sizing: border-box;
  border-top: ${props =>
    props.isEditing ? "2px solid #2680fa" : "1px solid lightgray"};

  &:hover {
    border-top: ${props =>
      props.readOnly ? "1px solid #2680fa" : "2px solid #2680fa"};
  }
`;

const DeleteConfirmContainer = styled.div`
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-left: 1rem;
  }
`;

const MetaData = styled.div`
  &&& {
    font-size: 14px;
  }
  width: 12rem;
  margin-bottom: 1rem;
`;

export default function NoteItem(props) {
  const { note = {}, notes = [], setNotes, id } = props;

  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleDelete = async noteId => {
    try {
      const url = Routes.note_url(noteId);

      const response = await fetch(url, {
        method: "DELETE",
        headers: formHeaders()
      });

      if (!response.ok) {
        alert("Note could not be deleted.");
      }

      if (response.ok) {
        // const json = await response.json();
        setNotes(notes.filter(n => n.id !== noteId));
      }
    } catch (error) {
      alert(error);
    }
  };

  const renderDelete = () => {
    const button = (
      <button
        style={{ justifyContent: "right" }}
        type="button"
        id={`delete-button-${note.id}`}
        className="text-button small secondary"
        onClick={() => setDeleteConfirmVisible(true)}
      >
        Delete
      </button>
    );
    if (deleteVisible && !deleteConfirmVisible) {
      return button;
    }
    if (deleteConfirmVisible) {
      return (
        <DeleteConfirmContainer>
          <strong>Delete this note?</strong>
          <div>
            <button
              type="button"
              className="text-button small cancel"
              onClick={() => setDeleteConfirmVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              id={`delete-confirm-button-${note.id}`}
              className="text-button small delete"
              onClick={() => handleDelete(note.id)}
            >
              Delete
            </button>
          </div>
        </DeleteConfirmContainer>
      );
    }
    if (isEditing) {
      return button;
    }
  };

  return (
    <NoteContainer
      id={id}
      readOnly={note.read_only}
      onMouseEnter={() => setDeleteVisible(true)}
      onMouseLeave={() => setDeleteVisible(false)}
      isEditing={isEditing}
    >
      {note.read_only && (
        <MetaData>
          <strong>{note.author_name}</strong>
          <div style={{ color: "#818DA4" }}>
            {moment(note.created_at).format("MMM Do, YYYY")}
          </div>
        </MetaData>
      )}
      <NoteEdit
        note={note}
        notes={notes}
        setNotes={setNotes}
        setRowSelected={setIsEditing}
        handleDelete={handleDelete}
      />
      {!note.read_only && renderDelete()}
    </NoteContainer>
  );
}

NoteItem.propTypes = {
  note: PropTypes.object,
  notes: PropTypes.array,
  setNotes: PropTypes.func
};
