import React from "react";
import PropTypes from "prop-types";
import { authenticityToken } from "../../../utils/form";
import UploadItem from "../../ClientInformation/FormFields/UploadItem";

export default function UscisDocumentUpload(props) {
  const { caseStatusId, uploadState, setUploadState, handleUpload } = props;

  return (
    <>
      {uploadState && (
        <UploadItem
          file={uploadState}
          setFiles={() => setUploadState()}
          attribute={{
            fileData: {
              entityId: caseStatusId,
              entityType: "CaseStatus"
            },
            name: "decision_status_document"
          }}
        />
      )}
      {!uploadState && (
        <form acceptCharset="UTF-8">
          <input type="hidden" name="_method" value="PUT" />
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken()}
          />
          <input type="hidden" name="entity_type" value="CaseStatus" />
          <input type="hidden" name="entity_id" value={caseStatusId} />
          <input type="hidden" name="add_item" value />
          <input
            name="entity_attributes[decision_status_document]"
            type="file"
            accept="image/png, image/jpeg, application/pdf"
            className="inputfile"
            id="case-status-document"
            multiple={false}
            onChange={e => handleUpload(e)}
          />
          <label
            htmlFor="case-status-document"
            className="button u_margin-top-1rem"
          >
            Upload USCIS Status
          </label>
        </form>
      )}
    </>
  );
}

UscisDocumentUpload.propTypes = {
  caseStatusId: PropTypes.string,
  uploadState: PropTypes.object,
  setUploadState: PropTypes.func,
  handleUpload: PropTypes.func
};
