/* eslint-disable react/destructuring-assignment */
import React from "react";

export default function EntityFields(props) {
  return (
    <div>
      <input type="hidden" name="entity_type" value={props.type || ""} />
      <input type="hidden" name="entity_id" value={props.id || ""} />
    </div>
  );
}
