/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";

export default function DocumentsRow(props) {
  const { doc } = props;

  const [documentState, setDocumentState] = useState({
    fileId: doc.fileId,
    id: doc.id,
    generatedAt: doc.dateGenerated
  });

  function documentNav() {
    return (
      <>
        <div className="actions-container generate">
          {documentState.fileId && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://drive.google.com/open?id=${documentState.fileId}`}
              className="small"
            >
              View
            </a>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="document-row petition-documents incomplete"
        key={doc.name}
      >
        <div className="info-icon">
          <div className="icon" />
        </div>
        <div className="document-title">{doc.name}</div>
        <div className="submitted-at-column">
          {documentState.generatedAt && (
            <div className="generated-info">
              Generated {` ${documentState.generatedAt}`} ago.
            </div>
          )}
        </div>
        <div className="actions-container">{documentNav()}</div>
      </div>
    </>
  );
}

DocumentsRow.propTypes = {
  doc: PropTypes.object
};
