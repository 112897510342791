import { render, fireEvent, waitFor } from "@testing-library/react";
import PetitionAssigneeSelect from "./PetitionAssigneeSelect";

// Mock the react-select component because it has a complicated DOM structure
// that is subject to change.  This makes testing easier and prevents rewriting
// tests if the react-select API changes
jest.mock("react-select", () => ({ options, value, onChange }) => {
  function handleChange(event) {
    const option = options.find(opt => opt.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select data-testid="select" value={value} onChange={handleChange}>
      {" "}
      {options.map(({ label, val }) => (
        <option key={Math.random()} value={val}>
          {" "}
          {label}{" "}
        </option>
      ))}{" "}
    </select>
  );
});

describe("The searchable select component", () => {
  window.alert = () => {};

  const petitionId = "PETITION_ID";
  const assigneeOptions = [
    {
      value: "jekyll_ID",
      label: "dr_jekyll@gmail.com"
    },
    {
      value: "hyde_ID",
      label: "mrhyde@yahoo.com"
    }
  ];
  const assigneeId = "jekyll_ID";

  beforeEach(() => {
    fetch.resetMocks();
  });

  it("renders without crashing", () => {
    const { container } = render(<PetitionAssigneeSelect />);
    expect(container);
  });

  it("displays the correct select options and the pre-selected value", () => {
    const { getByText } = render(
      <PetitionAssigneeSelect
        petitionId={petitionId}
        assigneeId={assigneeId}
        assigneeOptions={assigneeOptions}
      />
    );
    expect(getByText("dr_jekyll@gmail.com"));
  });

  it("fires the onChange handler when an option is selected", async () => {
    fetch.mockResponseOnce(
      JSON.stringify({ petitionId: "PETITION_ID", assigneeId: "hyde_ID" })
    );

    const { getByTestId } = render(
      <PetitionAssigneeSelect
        petitionId={petitionId}
        assigneeId={assigneeId}
        assigneeOptions={assigneeOptions}
      />
    );
    expect(fetch.mock.calls.length).toEqual(0);
    fireEvent.change(getByTestId("select"), {
      target: { value: "hyde_ID" }
    });
    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
      // The 'select' component's value remains the ID of whatever the first option
      // passed to the component. Need to find a different way to test this.
      // expect(getByTestId("select")).toHaveValue("hyde_ID")
    });
  });
});
