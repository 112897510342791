/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import CommonNav from "../Shared/CommonNav/CommonNav";
import NotesConsole from "../NotesConsole/NotesConsole";
import PetitionCard from "./PetitionCard";
import BlockerFlag from "../PetitionPage/PetitionOverview/PetitionActions/BlockerFlag";

const InfoRow = styled.div`
  &&& {
    margin: 0.8rem 0.8rem 0.8rem 0.8rem;
  }
`;

const ContactCard = styled.div`
  &&& {
    max-width: 420px;
    padding: 0;
  }
`;

const BlockerRow = styled.div`
  margin: 0 0 -2rem -1.5rem;
  color: #818da4;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export default function PetitionInformation(props) {
  debugger;
  const {
    beneficiaryId = null,
    beneficiaryName = "Betty Beneficiary",
    id = null,
    petitionInfo = [],
    beneficiaryInfo = [],
    signatoryInfo = [],
    assigneeInfo = [],
    attorneyInfo = [],
    representativeInfo = [],
    organizationInfo = [],
    jobInfo = [],
    ocInfo = [],
    awardsInfo = [],
    firmsInfo = [],
    fundingDocumentsInfo = [],
    accelerationsInfo = [],
    associationsInfo = [],
    criticalEmploymentsInfo = [],
    highRemunerationInfo = [],
    patentsInfo = [],
    pressInfo = [],
    articlesInfo = [],
    judingEventsInfo = [],
    advisoryInfo = [],
    info = {}
  } = props;

  return (
    <>
      <CommonNav
        home={{ text: "Petitions", href: "/petitions" }}
        title={{ text: beneficiaryName, href: `/petitions/${id}` }}
        subTitle={{
          text: "Petition Information",
          href: `/petitions/${id}/info`
        }}
      />
      <main className="petition-information" style={{display: 'flex', flexWrap: 'wrap'}}>
        <BlockerRow style={{width: "100%"}}>
        <BlockerFlag
          label='Personal'
          isActive={!beneficiaryInfo.length > 0}
        />
        <BlockerFlag
          label='Company'
          isActive={!organizationInfo.length > 0}
        />
        <BlockerFlag
          label='Job'
          isActive={!jobInfo.length > 0}
        />
          <BlockerFlag
            label='Original Contribution'
            isActive={!ocInfo.length > 0}
          />
          <BlockerFlag
            label='Traditional Awards'
            isActive={!awardsInfo.length > 0}
          />
          <BlockerFlag
            label='Investment Firms'
            isActive={!firmsInfo.length > 0}
          />
          <BlockerFlag
            label='Funding Documents'
            isActive={!fundingDocumentsInfo.length > 0}
          />
          <BlockerFlag
            label='Accelerators'
            isActive={!accelerationsInfo.length > 0}
          />
          <BlockerFlag
            label='Associations'
            isActive={!associationsInfo.length > 0}
          />
          <BlockerFlag
            label='Critical Employment'
            isActive={!criticalEmploymentsInfo.length > 0}
          />
          <BlockerFlag
            label='High Remuneration'
            isActive={!highRemunerationInfo.length > 0}
          />
          <BlockerFlag
            label='Patents'
            isActive={!patentsInfo.length > 0}
          />
          <BlockerFlag
            label='Press'
            isActive={!pressInfo.length > 0}
          />
          <BlockerFlag
            label='Scholarly Articles'
            isActive={!articlesInfo.length > 0}
          />
          <BlockerFlag
            label='Judging Events'
            isActive={!judingEventsInfo.length > 0}
          />
          <BlockerFlag
            label='Advisory Opinion'
            isActive={!advisoryInfo.length > 0}
          />
        </BlockerRow>
        <PetitionCard
          title="Petition"
          info={petitionInfo}
          missing={petitionInfo.length === 0}
        />
        <PetitionCard
          title="Beneficiary"
          info={beneficiaryInfo}
          missing={beneficiaryInfo.length === 0}
        />
        <PetitionCard
          title="Company"
          info={organizationInfo}
          missing={organizationInfo.length === 0}
        />
        <PetitionCard
          title="Signatory"
          info={signatoryInfo}
          missing={signatoryInfo.length === 0}
        />
        <PetitionCard
          title="Assignee"
          info={assigneeInfo}
          missing={assigneeInfo.length === 0}
        />
        <PetitionCard
          title="Attorney"
          info={attorneyInfo}
          missing={attorneyInfo.length === 0}
        />
        <PetitionCard
          title="Representative"
          info={representativeInfo}
          missing={representativeInfo.length === 0}
        />
        <PetitionCard
          title="Job"
          info={jobInfo}
          missing={jobInfo.length === 0}
        />
        <PetitionCard
          title="Original Contributions"
          info={ocInfo}
          missing={ocInfo.length === 0}
        />
        <PetitionCard
          title="Traditional Awards"
          info={awardsInfo}
          missing={awardsInfo.length === 0}
        />
        <PetitionCard
          title="Investment Firms"
          info={firmsInfo}
          missing={firmsInfo.length === 0}
        />
        <PetitionCard
          title="Accelerators"
          info={accelerationsInfo}
          missing={accelerationsInfo.length === 0}
        />
        <PetitionCard
          title="Funding Documents"
          info={fundingDocumentsInfo}
          missing={fundingDocumentsInfo.length === 0}
        />
        <PetitionCard
          title="Associations"
          info={associationsInfo}
          missing={associationsInfo.length === 0}
        />
        <PetitionCard
          title="Critical Employment"
          info={criticalEmploymentsInfo}
          missing={criticalEmploymentsInfo.length === 0}
        />
        <PetitionCard
          title="High Remuneration"
          info={highRemunerationInfo}
          missing={highRemunerationInfo.length === 0}
        />
        <PetitionCard
          title="Patents"
          info={patentsInfo}
          missing={patentsInfo.length === 0}
        />
        <PetitionCard
          title="Press"
          info={pressInfo}
          missing={pressInfo.length === 0}
        />
        <PetitionCard
          title="Scholarly Articles"
          info={articlesInfo}
          missing={articlesInfo.length === 0}
        />
        <PetitionCard
          title="Judging Events"
          info={judingEventsInfo}
          missing={judingEventsInfo.length === 0}
        />
        <PetitionCard
          title="Advisory Opinion"
          info={advisoryInfo}
          missing={advisoryInfo.length === 0}
        />
      </main>
      <NotesConsole
        source="Client Information"
        notable={{ id }}
        notableType="Petition"
      />
    </>
  );
}
