/* eslint-disable react/boolean-prop-naming */
import React from "react";
import PropTypes from "prop-types";
import SectionsMenu from "../../SectionsMenu";

export default function SideNav(props) {
  const {
    sections,
    formsSelected,
    setFormsSelected,
    lettersSelected,
    setLettersSelected,
    petitionGdriveLink
  } = props;

  return (
    <nav>
      <div className="nav-content-wrapper">
        <SectionsMenu sections={sections} />
        <div className="nav-item external">
          <h6>TOOLS</h6>
          <a
            style={{
              display: "inline-flex",
              justifyContent: "left",
              alignItems: "center",
              padding: "1rem 0"
            }}
            href={petitionGdriveLink}
            target="_blank"
            rel="noopener noreferrer"
            className="button text-button"
          >
            <img
              height={16}
              width={16}
              alt="google drive"
              // eslint-disable-next-line import/no-unresolved, global-require
              src={require("images/logo_gdrive.svg")}
              style={{ marginRight: ".75rem" }}
            ></img>
            Google Drive
          </a>
        </div>
      </div>
    </nav>
  );
}

SideNav.propTypes = {
  sections: PropTypes.array,
  formsSelected: PropTypes.bool,
  setFormsSelected: PropTypes.func,
  lettersSelected: PropTypes.bool,
  setLettersSelected: PropTypes.func
};
