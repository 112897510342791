import React from "react";
import styled from "styled-components";
import SectionsMenu from "../../PetitionPage/SectionsMenu";

const MenuItem = styled.a`
  i {
    width: 0.5rem;
    margin-right: 1.5rem;
  }
`;

export default function SideNav(props) {
  const { sections, engAccess } = props;
  return (
    <nav>
      <div className="nav-content-wrapper">
        <SectionsMenu sections={sections} />
        <div className="nav-item external">
          <h6>TOOLS</h6>
          <MenuItem
            href={Routes.report_petitions_path()}
            rel="noopener noreferrer"
            className="button text-button"
            style={{ marginTop: "1rem" }}
          >
            <i className="fal fa-download"></i>
            Petition Report
          </MenuItem>
          <MenuItem
            href={Routes.intake_report_petitions_path()}
            rel="noopener noreferrer"
            className="button text-button"
          >
            <i className="fal fa-download"></i>
            Intake Report
          </MenuItem>
          <MenuItem
            href={Routes.shipments_path()}
            rel="noopener noreferrer"
            className="button text-button"
          >
            <i className="fal fa-envelope"></i>
            Shipments
          </MenuItem>
          <MenuItem
            href={Routes.visa_bulletin_path()}
            rel="noopener noreferrer"
            className="button text-button"
          >
            <i className="fal fa-file-search"></i>
            Visa Bulletin
          </MenuItem>
          {engAccess && (
            <MenuItem
              href={Routes.admin_root_path()}
              rel="noopener noreferrer"
              className="button text-button"
            >
              <i className="fal fa-key"></i>
              Admin
            </MenuItem>
          )}
        </div>
      </div>
    </nav>
  );
}
