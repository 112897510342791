/* eslint-disable react/destructuring-assignment */
import React from "react";

export default class ParentFields extends React.Component {
  render() {
    return (
      <div>
        <input type="hidden" name="parent_type" value={this.props.type || ""} />
        <input type="hidden" name="parent_id" value={this.props.id || ""} />
        <input
          type="hidden"
          name="associated_as"
          value={this.props.associatedAs || ""}
        />
        <input
          type="hidden"
          name="column_name"
          value={this.props.columnName || ""}
        />
      </div>
    );
  }
}
