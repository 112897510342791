import React, { useState } from "react";
import styled from "styled-components";
import NoteInput from "../../../../NotesConsole/NoteInput/NoteInput";
import NoteRow from "../NoteRow/NoteRow";

const Table = styled.div`
  display: grid;
  border: 1px solid #e9edf0;
  border-radius: 6px;
  background-color: #ffffff;
  min-width: 26rem;
  padding: 1.5rem;
  width: auto;
`;

const AddNotes = styled.div`
  margin-bottom: ${props =>
    props.hasNotes && !props.inputVisible ? "1.5rem" : 0};
  display: flex;
  align-items: baseline;

  p {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

export default function NotesTable(props) {
  const { notes = [], notable, setNotes } = props;

  const initialState = () => {
    if (localStorage.getItem(`note-${notable.id}`)) {
      // Account for quotes in local storage - everything saved as a string
      return localStorage.getItem(`note-${notable.id}`).length > 2;
    }
    return false;
  };

  const [inputVisible, setInputVisible] = useState(initialState);

  return (
    <Table>
      <AddNotes inputVisible={inputVisible} hasNotes={notes.length > 0}>
        {notes.length === 0 && !inputVisible && (
          <p>No notes have been added to this petition.</p>
        )}
        {!inputVisible && (
          <button
            type="button"
            className="button text-button small"
            onClick={() => setInputVisible(!inputVisible)}
          >
            Add notes
          </button>
        )}
      </AddNotes>
      {inputVisible && (
        <NoteInput
          {...props}
          inputRef
          source="Petition Overview"
          handleBlur={() => setInputVisible(false)}
          setInputVisible={setInputVisible}
          margin
        />
      )}
      {notes.map((note, index) => (
        <NoteRow
          id={`note-row-${index + 1}`}
          key={note.id}
          note={note}
          notes={notes}
          setNotes={setNotes}
        />
      ))}
    </Table>
  );
}
