/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import NumberField from "./NumberField";

export default class HighRemunerationCompensationFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compensationUnit: this.props.compensationUnit,
      currencyIndicatorText: this.props.autocomplete.value
    };
  }

  _handleSelectChange(e) {
    this.setState({ compensationUnit: e.target.value });
  }

  _handleCurrencyChange(value) {
    this.setState({ currencyIndicatorText: value });
  }

  render() {
    return (
      <React.Fragment>
        <section className="input-prompt-section">
          <h4>What was your salary at that company?</h4>
          <div className="text-input-wrapper tiny inline">
            <NumberField
              value={this.props.compensationSalary || ""}
              prefix=""
              name={`${this.props.nameBase}[compensation_salary]`}
              precision={2}
              signed="false"
              placeholder="Salary"
            />
            <label>Salary</label>
          </div>
          <div className="text-input-wrapper small inline">
            <AutocompleteField
              {...this.props.autocomplete}
              onParentChange={e => this._handleCurrencyChange(e)}
            />
          </div>
          <span className="inline-modifier">per</span>
          <div className="text-input-wrapper tiny inline">
            <select
              name={`${this.props.nameBase}[compensation_salary_unit]`}
              value={this.state.compensationUnit || ""}
              onChange={e => this._handleSelectChange(e)}
            >
              <option value="year">year</option>
              <option value="hour">hour</option>
            </select>
          </div>
        </section>

        <section className="input-prompt-section">
          <h4>What was your bonus at that company?</h4>
          <div className="text-input-wrapper tiny inline">
            <NumberField
              value={this.props.compensationBonus || ""}
              prefix=""
              name={`${this.props.nameBase}[compensation_bonus]`}
              precision={2}
              signed="false"
              placeholder="Bonus"
            />
            <label>Bonus</label>
          </div>
          <span className="inline-modifier">
            in {this.state.currencyIndicatorText}
          </span>
        </section>

        <section className="input-prompt-section">
          <h4>
            What was the approximate value of your equity at that company?
          </h4>
          <h5>Only enter a value here if you are using equity to meet this criterion.</h5>
          <div className="text-input-wrapper tiny inline">
            <NumberField
              value={this.props.compensationEquity || ""}
              prefix=""
              name={`${this.props.nameBase}[compensation_equity_value]`}
              precision={2}
              signed="false"
              placeholder="Equity Value"
            />
            <label>Equity Value</label>
          </div>
          <span className="inline-modifier">
            in {this.state.currencyIndicatorText}
          </span>
        </section>
      </React.Fragment>
    );
  }
}

HighRemunerationCompensationFields.propTypes = {
  name: PropTypes.string,
  autocomplete: PropTypes.object,
  compensationSalary: PropTypes.number,
  compensationUnit: PropTypes.string,
  compensationBonus: PropTypes.number,
  compensationEquityValue: PropTypes.number
};
