/* eslint-disable react/destructuring-assignment */
import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export default function PhoneField(props) {
  const mask = [
    /[\d-() +]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/,
    /[\d-() ]/
  ];
  const {
    name,
    value,
    label,
    placeholder,
    handleInputChange,
    handleInputBlur
  } = props;

  return (
    <>
      <MaskedInput
        mask={mask}
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        guide={false}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
      {label && <label>{label}</label>}
    </>
  );
}

PhoneField.propTypes = {
  name: PropTypes.string, // name of the input
  placeholder: PropTypes.string, // placeholder value
  value: PropTypes.string, // default value
  label: PropTypes.string, // value for the label
  handleInputChange: PropTypes.func, // callback to handle input change
  handleInputBlur: PropTypes.func // callback to handle input blur
};
