import React, { useState } from "react";
import Select from "react-select";
import Loader from "react-loader-spinner";
import { formHeaders } from "../../utils/form";
import { grayDarkest } from "../../styles/colors";

export default function ContactCard(props) {
  const {
    petitionId,
    contact = {
      role: "",
      name: "",
      email: "",
      phone: "",
      id: "",
      isAttorney: false
    },
    attorneyOptions = []
  } = props;

  const defaultOption = attorneyOptions.find(
    option => option.value === contact.id
  );

  const [currentContact, setCurrentContact] = useState(contact);

  const [editing, setEditing] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const handleAttorneySelect = async selectedOption => {
    setSelectedValue(selectedOption);

    try {
      setLoading(true);
      const url = Routes.petition_set_attorney_path(petitionId);
      const requestBody = {
        attorney_id: selectedOption.value
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        alert("Petition attorney could not be updated");
        setLoading(false);
      }

      if (response.ok) {
        const json = await response.json();
        setCurrentContact(json);
        setEditing(false);
        setTimeout(() => setLoading(false), 600);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  if (currentContact) {
    return (
      <div className="contact-card">
        <div className="info-row">
          <div className="role">{contact.role.toUpperCase()}</div>
          {loading && (
            <Loader type="ThreeDots" color="#2680FA" height={32} width={32} />
          )}
          {!loading && !editing && (
            <div className="name">
              <a
                href={Routes.client_path(currentContact.id)}
                className="like-text"
              >
                {currentContact.name}
              </a>
            </div>
          )}
          {!loading && !editing && contact.isAttorney && (
            <a
              data-testid="attorney-edit-button"
              onClick={() => setEditing(true)}
              onKeyDown={() => setEditing(true)}
              role="button"
              tabIndex={0}
            >
              Change
            </a>
          )}
          {contact.isAttorney && editing && (
            <div data-testid="attorney-select" style={{ color: grayDarkest }}>
              <Select
                data-testid="select"
                value={selectedValue}
                options={attorneyOptions}
                onChange={handleAttorneySelect}
                className="petition-attorney-select"
                classNamePrefix="petition-attorney"
                placeholder="Select attorney..."
              />
            </div>
          )}
        </div>
        <div className="info-row">
          <div className="label">Email</div>
          {loading ? (
            <Loader type="ThreeDots" color="#2680FA" height={32} width={32} />
          ) : (
            <div className="value">{currentContact.email}</div>
          )}
        </div>
        <div className="info-row">
          <div className="label">Phone</div>
          {loading ? (
            <Loader type="ThreeDots" color="#2680FA" height={32} width={32} />
          ) : (
            <div className="value">{currentContact.phone}</div>
          )}
        </div>
      </div>
    );
  }
}
