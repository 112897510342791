/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useReducer, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import { useHotkeys } from "../../hooks/useHotKeys";
import NoteInput from "./NoteInput/NoteInput";
import NoteList from "./NoteList/NoteList";
import { formHeaders } from "../../utils/form";
import { grayLightest } from "../../styles/colors";
import { fetchReducer } from "../../reducers/fetchReducer";

const ConsoleWrapper = styled.div`
  resize: horizontal;
  overflow: auto;
  direction: rtl;
  width: 460px;
  min-width: 320px;
  max-width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  background-color: white;
  box-shadow: -15px 0 15px -15px gray;
  z-index: 2;
  overflow-y: auto;
`;

const NotesHeader = styled.h2`
  padding: 1rem;
  color: black;
`;

const EscapeHeader = styled.p`
  padding: 1rem;
  text-align: right;
  color: #b9bfcb;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* This weird construct can be used to overide global styles coming from ancient stylesheets */
  &&& {
    font-size: 14px;
    max-width: none;
  }

  i {
    margin-left: 1rem;
    padding: 0.5rem;
    font-size: 1.5rem;
  }
`;

const HotKeyButtonContainer = styled.p`
  background-color: ${grayLightest};
  border-radius: 4px;
  padding: 0.5rem;
  color: #3a4861;

  &&& {
    font-size: 14px;
  }

  button {
    margin-left: 1rem;
  }
`;

const MarkdownCheatSheetLink = styled.div`
  padding: 1rem;
  i {
    margin-right: 0.5rem;
  }
`;

export default function NotesConsole(props) {
  const { notable = {}, notableType = "Petition" } = props;
  const [consoleOpen, setConsoleOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [fetchState, dispatch] = useReducer(fetchReducer, {
    success: false,
    loading: false,
    response: null
  });

  useHotkeys("ctrl + n", () => setConsoleOpen(true));
  useHotkeys("esc", () => setConsoleOpen(false));

  useEffect(() => {
    const fetchNotes = async (type, notableId) => {
      dispatch({ type: "FETCH_STARTED" });
      try {
        // eslint-disable-next-line no-undef
        const url = Routes.notes_url({
          notable_type: type,
          notable_id: notableId
        });

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          dispatch({ type: "FETCH_ERROR" });
          alert("Something went wrong.");
        }

        if (response.ok) {
          const json = await response.json();
          setNotes(json.notes);
          dispatch({ type: "FETCH_COMPLETE", json });
        }
      } catch (error) {
        dispatch({ type: "FETCH_ERROR" });
        alert(error);
      }
    };
    fetchNotes(notableType, notable.id);
  }, [notable.id, notableType]);

  return (
    <>
      {consoleOpen && (
        <ConsoleWrapper>
          <div style={{ direction: "ltr" }}>
            <EscapeHeader>
              Press ESC to close
              <i
                data-testid="escape-icon"
                className="fal fa-times"
                id="close-notes-console"
                style={{ color: "#FF5C5C", cursor: "pointer" }}
                onClick={() => setConsoleOpen(false)}
              />
            </EscapeHeader>
            <MarkdownCheatSheetLink>
              <i className="fab fa-markdown"></i>
              Markdown syntax is now supported in the notes console. New to
              markdown? Check out{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ dipslay: "inline" }}
                href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet#emphasis"
              >
                this cheatsheet.
              </a>
            </MarkdownCheatSheetLink>
            <NotesHeader>Notes</NotesHeader>
            <NoteInput
              {...props}
              padding
              data-testid="note-input"
              isNotesConsole
              consoleOpen={consoleOpen}
              notes={notes}
              setNotes={setNotes}
            />
            <NoteList notes={notes} setNotes={setNotes} />
          </div>
        </ConsoleWrapper>
      )}
      {!consoleOpen && (
        <div style={{ position: "fixed", bottom: 10, right: 100 }}>
          {fetchState.loading && (
            <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
          )}
          {!fetchState.loading && (
            <HotKeyButtonContainer>
              {notes.length === 0
                ? "Press CTRL + N to"
                : "Press CTRL + N to add note"}
              <button
                type="button"
                className="text-button small"
                onClick={() => setConsoleOpen(true)}
              >
                {notes.length === 0 && "Add note"}
                {notes.length === 1 && `${notes.length} note`}
                {notes.length > 1 && `${notes.length} notes`}
              </button>
            </HotKeyButtonContainer>
          )}
        </div>
      )}
    </>
  );
}

NotesConsole.propTypes = {
  notable: PropTypes.object,
  notableType: PropTypes.string
};
