import React from "react";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../../Shared/AlertTemplate";
import PetitionOverview from "./PetitionOverview";

const options = {
  timeout: 5000,
  offset: "30px"
};

export default function PetitionOverviewWrapper(props) {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <PetitionOverview {...props} />
    </AlertProvider>
  );
}
