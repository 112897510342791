import { render, fireEvent, waitFor } from "@testing-library/react";
import UscisStatusWrapper from "./UscisStatusWrapper";
import { mockUser } from "../../../testHelpers/userMocks";

const mockCaseStatusResponse = {
  petitionId: "4b296ba7-e725-5736-b402-50f4d15b1ac7",
  id: "68b110e1-9314-4993-a364-8e7fd2ff3caf",
  shipmentDate: "2019-04-20T00:00:00.000-07:00",
  receiptDate: "2019-08-05T00:00:00.000-07:00",
  caseNumber: null,
  decisionDate: "2019-10-10T00:00:00.000-07:00",
  decisionStatus: null,
  createdAt: "2020-05-12T14:25:32.075-07:00",
  updatedAt: "2020-05-15T13:46:45.914-07:00",
  caseawareStatus: null,
  caseawareDetails: null,
  caseawareUpdatedAt: null
};

const mockCaseStatusResponse2 = {
  petitionId: "4b296ba7-e725-5736-b402-50f4d15b1ac7",
  id: "68b110e1-9314-4993-a364-8e7fd2ff3caf",
  shipmentDate: "2020-10-31T00:00:00.000-07:00",
  receiptDate: "2020-01-01T00:00:00.000-07:00",
  caseNumber: "USCIS-FAKE-CASE-123",
  decisionDate: "2020-01-31T00:00:00.000-07:00",
  decisionStatus: null,
  createdAt: "2020-05-12T14:25:32.075-07:00",
  updatedAt: "2020-05-15T13:46:45.914-07:00",
  caseawareStatus: null,
  caseawareDetails: null,
  caseawareUpdatedAt: null
};

const mockCaseStatusResponse3 = {
  petitionId: "4b296ba7-e725-5736-b402-50f4d15b1ac7",
  id: "68b110e1-9314-4993-a364-8e7fd2ff3caf",
  shipmentDate: "2020-10-31T00:00:00.000-07:00",
  receiptDate: "2020-01-01T00:00:00.000-07:00",
  caseNumber: "USCIS-FAKE-CASE-123",
  decisionDate: null,
  decisionStatus: null,
  createdAt: "2020-05-12T14:25:32.075-07:00",
  updatedAt: "2020-05-15T13:46:45.914-07:00",
  caseawareStatus: null,
  caseawareDetails: null,
  caseawareUpdatedAt: null
};

describe("The USCISstatus component", () => {
  // eslint-disable-next-line no-console
  window.alert = alert => console.warn(alert);

  // fetch mock for header
  beforeEach(() => {
    fetch.once(JSON.stringify(mockUser));
  });

  afterEach(() => fetch.resetMocks());

  it("renders without crashing and makes one fetch call on load for the common nav", async () => {
    const { container, getByText } = render(
      <UscisStatusWrapper beneficiaryName="Homer Simpson" />
    );
    await waitFor(() => {
      expect(container);
      expect(getByText("Track and update USCIS Decisions for Homer Simpson"));
      expect(fetch.mock.calls.length).toEqual(1);
    });
  });

  it("allows the user to enter and update the shipment date", async () => {
    fetch.once(JSON.stringify(mockCaseStatusResponse));
    const { getByText, getByTestId } = render(<UscisStatusWrapper />);
    const shipmentDateInput = getByTestId("shipmentDate-input");
    fireEvent.change(shipmentDateInput, { target: { value: "2019-04-20" } });
    fireEvent.blur(shipmentDateInput);
    await waitFor(() => expect(getByTestId("shipmentDate-edit-button")));
    expect(getByText("04/20/2019"));

    fetch.once(JSON.stringify(mockCaseStatusResponse2));
    fireEvent.click(getByTestId("shipmentDate-edit-button"));
    const newShipmentDateInput = getByTestId("shipmentDate-input");
    fireEvent.change(newShipmentDateInput, { target: { value: "2020-10-31" } });
    fireEvent.blur(newShipmentDateInput);
    await waitFor(() => expect(getByTestId("shipmentDate-edit-button")));
    expect(getByText("10/31/2020"));
  });

  it("allows the user to enter and update the receipt date", async () => {
    fetch.once(JSON.stringify(mockCaseStatusResponse));
    const { getByText, getByTestId } = render(<UscisStatusWrapper />);
    const receiptDateInput = getByTestId("receiptDate-input");
    fireEvent.change(receiptDateInput, { target: { value: "2019-08-05" } });
    fireEvent.blur(receiptDateInput);
    await waitFor(() => expect(getByTestId("receiptDate-edit-button")));
    expect(getByText("08/05/2019"));

    fetch.once(JSON.stringify(mockCaseStatusResponse2));
    fireEvent.click(getByTestId("receiptDate-edit-button"));
    const newReceiptDateInput = getByTestId("receiptDate-input");
    fireEvent.change(newReceiptDateInput, { target: { value: "2020-01-01" } });
    fireEvent.blur(newReceiptDateInput);
    await waitFor(() => expect(getByTestId("receiptDate-edit-button")));
    expect(getByText("01/01/2020"));
  });

  it("allows the user to enter and update the case number", async () => {
    fetch.once(JSON.stringify(mockCaseStatusResponse));
    const { getByText, getByTestId } = render(<UscisStatusWrapper />);
    const receiptDateInput = getByTestId("receiptDate-input");
    fireEvent.change(receiptDateInput, { target: { value: "2019-08-05" } });
    fireEvent.blur(receiptDateInput);
    await waitFor(() => {
      expect(getByTestId("caseNumber-input"));
      getByTestId("receiptDate-edit-button");
    });

    fetch.once(JSON.stringify(mockCaseStatusResponse3));
    const caseNumberInput = getByTestId("caseNumber-input");
    fireEvent.change(caseNumberInput, {
      target: { value: "USCIS-FAKE-CASE-123" }
    });
    fireEvent.blur(caseNumberInput);
    await waitFor(() => expect(getByTestId("caseNumber-edit-button")));
    expect(getByText("USCIS-FAKE-CASE-123"));
    fireEvent.click(getByTestId("caseNumber-edit-button"));
    const newCaseNumberInput = getByTestId("caseNumber-input");
    fireEvent.change(newCaseNumberInput, {
      target: { value: "NEW-CASE-NUMBER-456" }
    });
    fireEvent.blur(newCaseNumberInput);
    await waitFor(() => {
      getByTestId("caseNumber-edit-button");
    });
    expect(getByText("NEW-CASE-NUMBER-456"));
  });

  it("allows the user to enter and update the decision date", async () => {
    fetch.once(JSON.stringify(mockCaseStatusResponse));
    const { getByText, getByTestId } = render(<UscisStatusWrapper />);
    const decisionDateInput = getByTestId("decisionDate-input");
    fireEvent.change(decisionDateInput, { target: { value: "2019-10-10" } });
    fireEvent.blur(decisionDateInput);
    await waitFor(() => expect(getByTestId("decisionDate-edit-button")));
    expect(getByText("10/10/2019"));

    fetch.once(JSON.stringify(mockCaseStatusResponse2));
    fireEvent.click(getByTestId("decisionDate-edit-button"));
    const newDecisionDateInput = getByTestId("decisionDate-input");
    fireEvent.change(newDecisionDateInput, { target: { value: "2020-01-31" } });
    fireEvent.blur(newDecisionDateInput);
    await waitFor(() => expect(getByTestId("decisionDate-edit-button")));
    expect(getByText("01/31/2020"));
  });
});
