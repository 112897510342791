import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAlert } from "react-alert";
import SubHeader from "../SubHeader";
import IntakeRow from "../IntakeRow";
import ContactCard from "../ContactCard";
import SectionsMenu from "../SectionsMenu";
import PetitionActions from "./PetitionActions/PetitionActions";
import SectionsDropdowns from "../../Shared/SectionDropdowns/SectionDropdowns";
import NotesDropdown from "./NotesDropdown/NotesDropdown";
import PopupMessage from "../../PopupMessage/PopupMessage";
import CommonNav from "../../Shared/CommonNav/CommonNav";
import { formHeaders } from "../../../utils/form";
import OrganizationPersonForm from "../../Shared/Forms/OrganizationPersonForm";

const IntakeTable = styled.div`
  display: grid;
  min-width: 68rem;
`;

const IntakeHeaderRow = styled.div``;

export default function PetitionOverview(props) {
  const {
    beneficiary,
    petitionRepresentative,
    attorney,
    attorneyOptions,
    petitionerName,
    organizationId,
    hasDependents,
    intakes,
    intakeUserOptions,
    petitionGdriveLink,
    petitionId,
    petitionName,
    petitionBlockers,
    type,
    token,
    noteProps,
    assigneeId,
    assigneeOptions,
    archived,
    dealId,
    features = {}
  } = props;

  const [errors, setErrors] = useState([]);
  const popup = useAlert();
  const [showArchived, setShowArchived] = useState(archived);

  const handleArchive = async unarchive => {
    // Pass 'unarchive' or true to this function to unarchive

    try {
      const url = unarchive
        ? Routes.unarchive_petition_path(petitionId)
        : Routes.archive_petition_path(petitionId);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show(response.error.message, { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setShowArchived(json.archived);
        const message = unarchive
          ? "Your petition has been unarchived"
          : "Your petition has been archived";
        popup.show(message, { type: "success" });
      }
    } catch (error) {
      popup.show("Something went wrong. Please contact Legalpad Engineering.", {
        type: "error"
      });
    }
  };

  const availableContacts = () =>
    [beneficiary, petitionRepresentative, attorney].filter(
      contact => !!contact
    );

  const renderFlags = (
    <PetitionActions petitionId={petitionId} blockers={petitionBlockers} />
  );

  const renderIntakeRows = () => (
    <>
      <IntakeTable>
        <IntakeHeaderRow className="document-headers intake">
          <div className="info-icon" />
          <div className="title intake-title" />
          <div className="info-column dark">Recipient</div>
          <div className="info-column dark">Organization</div>
          <div className="submitted-at-column" />
          <div className="actions-container" />
        </IntakeHeaderRow>
        {intakes.map((intake, index) => (
          <IntakeRow
            intake={intake}
            userOptions={intakeUserOptions}
            petitionerName={petitionerName}
            key={index}
          />
        ))}
      </IntakeTable>
      <OrganizationPersonForm
        organizationId={organizationId}
        // eslint-disable-next-line no-restricted-globals
        updateParentObject={() => location.reload()}
      />
    </>
  );

  const renderContactCards = () => {
    const cards = availableContacts().map((contact, index) => (
      <ContactCard
        contact={contact}
        key={index}
        petitionId={petitionId}
        attorneyOptions={attorneyOptions}
      />
    ));

    return <div className="contact-cards">{cards}</div>;
  };

  const renderNotes = () => (
    <NotesDropdown
      errors={errors}
      setErrors={setErrors}
      notable={noteProps.notable}
      notableType={noteProps.notableType}
    />
  );

  const renderActionsSection = () => (
    <>
      {!showArchived && (
        <p>
          <button
            tabIndex={0}
            type="button"
            className="button small destructive"
            onKeyDown={() =>
              window.confirm(
                "Are you sure you want to archive this petition?"
              ) && handleArchive()
            }
            onClick={() =>
              window.confirm(
                "Are you sure you want to archive this petition?"
              ) && handleArchive()
            }
          >
            Archive this petition
          </button>
        </p>
      )}
      {showArchived && (
        <p>
          <a
            tabIndex={0}
            role="button"
            className="warning"
            onKeyDown={() => handleArchive("unarchive")}
            onClick={() => handleArchive("unarchive")}
          >
            Unarchive this petition
          </a>
        </p>
      )}
    </>
  );

  const sections = [
    {
      name: "Flags",
      content: renderFlags
    },
    {
      name: "Contacts",
      content: renderContactCards()
    },
    {
      name: "Intakes",
      content: renderIntakeRows()
    },
    {
      name: "Notes",
      content: renderNotes()
    },
    {
      name: "Actions",
      content: renderActionsSection()
    }
  ];

  const SideNav = () => (
    <nav>
      <div className="nav-content-wrapper">
        <SectionsMenu sections={sections} />
        <div className="nav-item external">
          <h6>TOOLS</h6>
          <a
            href={Routes.edit_petition_path(petitionId)}
            rel="noopener noreferrer"
            className="button text-button"
            style={{ padding: "1rem 0" }}
          >
            <i className="fas fa-wrench" style={{ marginRight: ".75rem" }} />
            Edit
          </a>

          <a
            href={Routes.petition_templates_path(petitionId)}
            className="button text-button"
          >
            <i
              className="fas fa-folder-open"
              style={{ marginRight: ".75rem" }}
            />
            Templates
          </a>
          <a
            href={Routes.petition_case_status_path(petitionId)}
            rel="noopener noreferrer"
            className="button text-button"
          >
            <i className="fas fa-landmark" style={{ marginRight: ".75rem" }} />
            USCIS Status
          </a>
          <a
            href={Routes.new_shipment_path({ petition_id: petitionId })}
            rel="noopener noreferrer"
            className="button text-button"
          >
            <i className="fas fa-envelope" style={{ marginRight: ".75rem" }} />
            Shipping
          </a>
          <a
            href={Routes.petition_evidence_request_path(petitionId)}
            rel="noopener noreferrer"
            className="button text-button"
          >
            <i className="fas fa-archive" style={{ marginRight: ".75rem" }} />
            Evidence
          </a>
          <a
            style={{
              display: "inline-flex",
              justifyContent: "left",
              alignItems: "center"
            }}
            href={petitionGdriveLink}
            target="_blank"
            rel="noopener noreferrer"
            className="button text-button"
          >
            <img
              height={16}
              width={16}
              alt="google drive"
              // eslint-disable-next-line import/no-unresolved, global-require
              src={require("images/logo_gdrive.svg")}
              style={{ marginRight: ".75rem" }}
            ></img>
            Google Drive
          </a>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      {errors &&
        errors.map(error => (
          <PopupMessage
            popupType="error"
            message={error.message}
            key={Math.random()}
          />
        ))}
      <CommonNav
        home={{ text: "Petitions", href: "/petitions" }}
        title={{ text: petitionName, href: "#" }}
      />
      <SideNav petitionId={petitionId} />

      <main>
        <SubHeader
          type={type}
          token={token}
          beneficiary={beneficiary}
          hasDependents={hasDependents}
          petitionerName={petitionerName}
          assigneeOptions={assigneeOptions}
          assigneeId={assigneeId}
          petitionId={petitionId}
          dealId={dealId}
        />
        <SectionsDropdowns sections={sections} />
      </main>
    </>
  );
}

PetitionOverview.propTypes = {
  beneficiary: PropTypes.object,
  petitionRepresentative: PropTypes.object,
  attorney: PropTypes.object,
  petitionerName: PropTypes.string,
  intakes: PropTypes.array,
  intakeUserOptions: PropTypes.array,
  petitionGdriveLink: PropTypes.string,
  petitionId: PropTypes.string,
  petitionName: PropTypes.string,
  petitionBlockers: PropTypes.object,
  type: PropTypes.string,
  token: PropTypes.string,
  assigneeOptions: PropTypes.array,
  assigneeId: PropTypes.string,
  archived: PropTypes.bool,
  features: PropTypes.object
};
