import { render, fireEvent, waitFor } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import EmployeeIndexPage from "./EmployeeIndexPage";
import AlertTemplate from "../Shared/AlertTemplate";
import { mockUser } from "../../testHelpers/userMocks";

describe("The EmployeeIndexPage component", () => {
  beforeEach(() => {
    fetch.mockResponses(
      [JSON.stringify(mockUser)],
      [
        JSON.stringify({
          employees: [
            {
              id: "123",
              fullName: "Homer Simpson",
              email: "homer@legalpad.io",
              createdAt: "2020-10-30T09:08:21.107-08:00",
              confirmed: true
            },
            {
              id: "456",
              fullName: "Barney Gumble",
              email: "barney@legalpad.io",
              createdAt: "2020-10-30T09:08:25.107-07:00",
              confirmed: true
            },
            {
              id: "789",
              fullName: "Professor McGonnagal",
              email: "hogwarts4@legalpad.io",
              createdAt: "2020-10-30T09:08:20.107-15:00",
              confirmed: true
            }
          ]
        })
      ]
    );
  });

  it("renders without crashing", () => {
    const { container } = render(
      <AlertProvider template={AlertTemplate}>
        <EmployeeIndexPage />
      </AlertProvider>
    );
    expect(container);
  });

  it("renders employees and can search for an employee without crashing", async () => {
    const { getByTestId, getByText, queryByText } = render(
      <AlertProvider template={AlertTemplate}>
        <EmployeeIndexPage />
      </AlertProvider>
    );
    await waitFor(() => expect(getByText("Barney Gumble")));
    expect(queryByText("Homer Simpson")).toBeInTheDocument();
    expect(queryByText("Professor McGonnagal")).toBeInTheDocument();
    const searchbar = getByTestId("employee-index-searchbar");
    fireEvent.change(searchbar, { target: { value: "remuner" } });
    expect(getByTestId("employee-index-searchbar"));

    fireEvent.change(searchbar, { target: { value: "Barn" } });
    await waitFor(() => {
      expect(getByText("Barney Gumble"));
      expect(queryByText("Homer Simpson")).not.toBeInTheDocument();
      expect(queryByText("Professor McGonnagal")).not.toBeInTheDocument();
    });
  });
});
