import React from "react";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../../Shared/AlertTemplate";
import UscisStatus from "./UscisStatus";

const options = {
  // you can also just use 'bottom center'
  timeout: 5000,
  offset: "30px"
  // you can also just use 'scale'
};

export default function UscisStatusWrapper(props) {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <UscisStatus {...props} />
    </AlertProvider>
  );
}
