/* eslint-disable react/destructuring-assignment */
import React from "react";
import { authenticityToken } from "../../utils/form";

export default class Form extends React.Component {
  /* A generic form wrapper that takes care of charset, auth tokens, etc

   Props:
   {
      method<string>:          GET, POST, etc
      onSubmit<func>:          Callback to be executed on form submit
      children<DOMElements>:   Elements to be inserted in the Form
   }
  */

  render() {
    return (
      <form onSubmit={this.props.onSubmit} acceptCharset="UTF-8">
        <input name="utf8" type="hidden" value="&#x2713;" />
        <input type="hidden" name="_method" value={this.props.method} />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken()}
        />
        {this.props.children}
      </form>
    );
  }
}
