import { render } from "@testing-library/react";
import NotesTable from "./NotesTable";
import { LocalStorageMock } from "../../../../../testHelpers/localStorageMock";
import { mockNotesProps } from "../../../../../testHelpers/notesMocks";

const { notable } = mockNotesProps;

describe("The NotesTable component", () => {
  global.localStorage = new LocalStorageMock();
  it("renders without crashing", () => {
    const { container } = render(<NotesTable notable={notable} />);
    expect(container);
  });

  it("displays the 'add notes' by default", () => {
    const { getByText } = render(<NotesTable notable={notable} />);
    expect(getByText("Add notes"));
  });

  it("displays the note input when there is a note in local storage", () => {
    // eslint-disable-next-line quotes
    localStorage.setItem(`note-${notable.id}`, '"The kids are alright."');
    const { getByText, queryByText } = render(
      <NotesTable notable={mockNotesProps.notable} />
    );
    expect(queryByText("Add notes")).not.toBeInTheDocument();
    expect(getByText("The kids are alright."));
  });
});
