/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

export default function DocumentDetailBar(props) {
  const { petitionId, name, generate, genState } = props;

  return (
    <header className="fixed-header">
      <div className="left doc-header">
        <div className="name">
          <a href={Routes.petition_templates_path(petitionId)}>
            <i className="far fa-chevron-left" />
            Templates
          </a>
        </div>
        <div className="doc-title">
          <i className="far fa-chevron-right gray" />
          {name}
        </div>
      </div>
      <div className="right">
        {genState.loading && (
          <div style={{ margin: "0 1.9rem" }}>
            <Loader type="ThreeDots" color="#2680FA" height={32} width={32} />
          </div>
        )}
        {!genState.loading && (
          <a className="button small primary" onClick={() => generate()}>
            Generate
          </a>
        )}
      </div>
    </header>
  );
}

DocumentDetailBar.propTypes = {
  petitionId: PropTypes.string,
  name: PropTypes.string,
  generate: PropTypes.func,
  genState: PropTypes.object
};
