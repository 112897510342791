import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "../../Shared/AlertTemplate";
import CommonNav from "../../Shared/CommonNav/CommonNav";
import SubHeader from "../SubHeader";
import SideNav from "./PetitionDocumentsSideNav/PetitionDocumentsSideNav";
import Dropdown from "../../Shared/Dropdown/__Dropdown";
import DocumentsRow from "./DocumentsRow/DocumentsRow";
import TemplatesRow from "./TemplatesRow/TemplatesRow";

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
};

const Table = styled.div`
  display: grid;
  min-width: 1000px;
`;

const HeaderRow = styled.div``;

export default function PetitionDocuments(props) {
  const [formsSelected, setFormsSelected] = useState(false);
  const [lettersSelected, setLettersSelected] = useState(false);

  const sections = [{ name: "Templates" }, { name: "Generated Documents" }];

  const {
    type,
    petitionId,
    petitionGdriveLink,
    petitionerName,
    beneficiary,
    assigneeId,
    assigneeOptions,
    documents,
    templates
  } = props;

  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <CommonNav
        home={{ text: "Petitions", href: "/petitions" }}
        title={{ text: beneficiary.name, href: `/petitions/${petitionId}` }}
        subTitle={{
          text: "Documents",
          href: `/petitions/${petitionId}/petition_documents`
        }}
      />
      <SideNav
        sections={sections}
        formsSelected={formsSelected}
        setFormsSelected={setFormsSelected}
        lettersSelected={lettersSelected}
        setLettersSelected={setLettersSelected}
        petitionGdriveLink={petitionGdriveLink}
      />
      <main>
        <SubHeader
          type={type}
          beneficiary={beneficiary}
          petitionerName={petitionerName}
          assigneeOptions={assigneeOptions}
          assigneeId={assigneeId}
          petitionId={petitionId}
        />
        <section id="templates" key="templates">
          <Dropdown
            name="Templates"
            dropdownContent={
              <Table>
                <HeaderRow>
                  <div className="info-icon"> </div>
                  <div className="document-title"> </div>
                  <div className="submitted-at-column" />
                  <div className="actions-container" />
                </HeaderRow>
                {templates.length === 0 && (
                  <div>
                    There are no templates available for this petition type.
                  </div>
                )}
                {templates.length > 0 &&
                  templates.map((template, index) => (
                    <TemplatesRow
                      key={index}
                      petitionId={petitionId}
                      template={template}
                    />
                  ))}
              </Table>
            }
          />
        </section>
        <section id="generateddocuments" key="generateddocuments">
          <Dropdown
            name="Generated Documents"
            dropdownContent={
              <Table>
                <HeaderRow className="document-headers petition-documents">
                  <div className="info-icon"> </div>
                  <div className="document-title"> </div>
                  <div className="actions-container" />
                </HeaderRow>
                {documents.length === 0 && (
                  <div>There are currently no documents</div>
                )}
                {documents.length > 0 &&
                  documents.map((doc, index) => (
                    <DocumentsRow
                      key={index}
                      petitionId={petitionId}
                      doc={doc}
                    />
                  ))}
              </Table>
            }
          />
        </section>
      </main>
    </AlertProvider>
  );
}

PetitionDocuments.propTypes = {
  type: PropTypes.string,
  petitionId: PropTypes.string,
  petitionGdriveLink: PropTypes.string,
  petitionerName: PropTypes.string,
  beneficiary: PropTypes.object,
  assigneeOptions: PropTypes.array,
  assigneeId: PropTypes.string
};
