import React from "react";
import PropTypes from "prop-types";

export default function EvidenceStatusPage(props) {
  const { evidenceRequest } = props;

  return (
    <div>
      <h1>Your evidence request has been sent!</h1>

      {!evidenceRequest && (
        <p>
          Your evidence request is not linked to google drive. Please contact a
          Legalpad engineer for assistance.
        </p>
      )}

      {evidenceRequest && (
        <>
          <p>
            You can view the spreadsheet with your evidence requests{" "}
            <a href={evidenceRequest.google_sheet_url}>here.</a>
          </p>
          <p>
            The evidence folder can be found{" "}
            <a href={evidenceRequest.gdrive_folder_url}>here.</a>
          </p>
        </>
      )}
    </div>
  );
}

EvidenceStatusPage.propTypes = {
  evidenceRequest: PropTypes.object
};
