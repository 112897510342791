/* eslint-disable react/destructuring-assignment */
import React from "react";

// Wraps a component and supplies an onChange(value) callback prop.  Changed
// values are stored as JSON in the input field specified by inputId.
export default class ReactField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: JSON.parse(this.inputElement().value)
    };
  }

  inputElement() {
    return document.getElementById(this.props.inputId);
  }

  handleChange(value) {
    this.inputElement().value = JSON.stringify(value);
    this.setState({ value });
  }

  render() {
    const { componentName, componentProps, valueProp } = this.props;
    const { value } = this.state;
    // eslint-disable-next-line no-undef
    const ctor = ReactRailsUJS.getConstructor(componentName);
    return React.createElement(ctor, {
      ...componentProps,
      [valueProp]: value,
      onChange: v => this.handleChange(v)
    });
  }
}
