import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import DocumentDetailBar from "./DocumentDetailBar";
import AttributeRow from "../../ClientInformation/FormFields/AttributeRow";
import PopupMessage from "../../PopupMessage/PopupMessage";
import { dataFromAttributeRows } from "../../../utils/data_presenter";
import { generateDocumentReducer } from "../../../reducers/generateDocumentReducer";
import { formHeaders } from "../../../utils/form";

export default function DocumentDetail(props) {
  const { petitionId, name, templateId, attributes } = props;

  const [genState, dispatch] = useReducer(generateDocumentReducer, {
    generated: false,
    loading: false,
    response: null,
    popupMessage: null,
    popupType: null
  });

  const [alertVisible, setAlertVisible] = useState(false);

  const handleGenerate = async () => {
    dispatch({ type: "GENERATE_STARTED" });

    try {
      const templateData = dataFromAttributeRows(
        Array.from(document.getElementsByClassName("attribute"))
      );
      const params = {
        petition_id: petitionId,
        template_id: templateId
      };

      const url = Routes.webmerge_generate_url(params);

      const requestBody = {
        template_data: templateData
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        dispatch({ type: "GENERATE_ERROR", popupMessage: response });
        setAlertVisible(true);
      }

      if (response.ok) {
        const json = await response.json();
        dispatch({ type: "GENERATE_COMPLETE", response: json });
        setAlertVisible(true);
        window.location = `${Routes.petition_templates_url(
          petitionId
        )}#generateddocuments`;
      }
    } catch (error) {
      dispatch({ type: "GENERATE_ERROR", popupMessage: error });
      setAlertVisible(true);
    }

    setTimeout(() => {
      setAlertVisible(false);
    }, 4000);
  };

  const renderAttribute = (attribute, index) => (
    <AttributeRow
      type={attribute.type}
      id={attribute.id}
      attribute={attribute}
      key={index}
      noSubmit={attribute.noSubmit || !attribute.id}
    />
  );

  return (
    <React.Fragment>
      <DocumentDetailBar
        petitionId={petitionId}
        name={name}
        generate={handleGenerate}
        genState={genState}
      />
      {alertVisible && (
        <PopupMessage
          popupType={genState.popupType}
          message={genState.popupMessage}
        />
      )}
      <main>
        <section className="header-titles">
          <div className="title">
            <h2>{name}</h2>
          </div>
        </section>
        <section>
          {attributes.map((attribute, index) =>
            renderAttribute(attribute, index)
          )}
        </section>
      </main>
    </React.Fragment>
  );
}

DocumentDetail.propTypes = {
  petitionId: PropTypes.string,
  name: PropTypes.string,
  templateId: PropTypes.string,
  attributes: PropTypes.array
};
