import React, { useState, useEffect } from "react";
import Select from "react-select";

export default function UscisDecisionStatusSelect(props) {
  const {
    decisionStatusTypes,
    decisionStatusState,
    setFormState,
    handleSubmit
  } = props;

  const statusOptions = decisionStatusTypes.map((t, i) => ({
    value: i,
    label: t
  }));

  const defaultOption = statusOptions.find(
    option => option.label === decisionStatusState
  );

  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const handleSelectChange = selectedOption => {
    setSelectedValue(selectedOption);
    setFormState(prevState => ({
      ...prevState,
      decisionStatus: selectedOption.label,
      dirty: true
    }));
  };

  // submit the form any time the decision status changes
  useEffect(() => {
    handleSubmit();
  }, [decisionStatusState]);

  return (
    <div style={{ width: "12rem" }}>
      <Select
        value={selectedValue}
        options={statusOptions}
        onChange={handleSelectChange}
        className="decision-status-select"
        classNamePrefix="decision-status"
        placeholder="Select status..."
        data-testid="select"
      />
    </div>
  );
}
