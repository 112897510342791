/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { usePagination } from "../../../hooks/usePagination";
import PetitionRow from "../PetitionRow/PetitionRow";
import { IndexRow } from "../../Shared/IndexRow/IndexRow";
import { PaginationWidget } from "../../Shared/PaginationWidget/PaginationWidget";
import { alphabeticalSort, chronologicalSort } from "../../../utils/sorting";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect";

const IndexGrid = styled.div`
  display: grid;
`;

export default function PetitionsTable(props) {
  const {
    groupName,
    petitionGroup,
    maxPerPage = 10,
    setPetitionsState,
    searchString
  } = props;

  const [showingAll, setShowingAll] = useState(false);
  const [sortBy, setSortBy] = useState({
    attribute: "updatedAt",
    ascending: true
  });

  const handleSort = attribute => {
    setSortBy(prevState => ({
      attribute,
      ascending: prevState.attribute === attribute ? !sortBy.ascending : true
    }));
  };

  const currentSortFunction = (a, b) => {
    switch (sortBy.attribute) {
      case "beneficiaryName":
        return alphabeticalSort(
          a.beneficiaryName,
          b.beneficiaryName,
          sortBy.ascending
        );
      case "petitioner":
        return alphabeticalSort(a.petitioner, b.petitioner, sortBy.ascending);
      case "assignee":
        return alphabeticalSort(a.assignee, b.assignee, sortBy.ascending);
      case "visaType":
        return alphabeticalSort(a.visaType, b.visaType, sortBy.ascending);
      case "createdAt":
        return chronologicalSort(a.createdAt, b.createdAt, sortBy.ascending);
      case "updatedAt":
        return chronologicalSort(
          a.updatedAt[0],
          b.updatedAt[0],
          sortBy.ascending
        );
      // By default petitions will be sorted by last updated
      default:
        return chronologicalSort(
          a.updatedAt[0],
          b.updatedAt[0],
          sortBy.ascending
        );
    }
  };

  const currentSortArrow = attribute => {
    if (sortBy.attribute === attribute && sortBy.ascending) return "↑";
    if (sortBy.attribute === attribute && !sortBy.ascending) return "↓";
  };

  const sortedPetitionGroup = petitionGroup.sort((a, b) =>
    currentSortFunction(a, b)
  );

  const {
    nextPage,
    prevPage,
    currentPage,
    maxPage,
    showAll,
    setCurrentPage,
    repaginate,
    currentData,
    startIndex,
    endIndex
  } = usePagination(sortedPetitionGroup, maxPerPage);

  useDidMountEffect(() => {
    setCurrentPage(1);
  }, [searchString]);

  const handleShowAllClick = () => {
    window.location = `#${groupName.toLowerCase().replace(/\s/g, "")}`;
    window.scrollBy(0, -70);
    showAll();
    setShowingAll(true);
  };

  const handleRepaginateClick = () => {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );
    repaginate();
    setShowingAll(false);
  };

  return (
    <>
      {petitionGroup.length === 0 && <p>No results found...</p>}
      {petitionGroup.length > 0 && (
        <PaginationWidget
          currentPage={currentPage}
          maxPage={maxPage}
          showingAll={showingAll}
          upshift
        >
          <p>{`${startIndex} - ${endIndex} of ${petitionGroup.length}`}</p>

          {petitionGroup.length > maxPerPage && (
            <div>
              {!showingAll && (
                <a data-turbolinks={false} onClick={() => handleShowAllClick()}>
                  Show all
                </a>
              )}
              {showingAll && (
                <a onClick={() => handleRepaginateClick()}>Back to page view</a>
              )}
            </div>
          )}
          {petitionGroup.length > maxPerPage && (
            <div>
              <i onClick={() => prevPage()} className="fas fa-chevron-left" />
              <i onClick={() => nextPage()} className="fas fa-chevron-right" />
            </div>
          )}
        </PaginationWidget>
      )}
      <IndexGrid>
        {petitionGroup.length > 0 && (
          <IndexRow as="div">
            <div
              data-testid="petition"
              onClick={() => handleSort("beneficiaryName")}
            >
              Petition {currentSortArrow("beneficiaryName")}
            </div>
            <div data-testid="visa type" onClick={() => handleSort("visaType")}>
              Visa type {currentSortArrow("visaType")}
            </div>
            <div
              data-testid="date created"
              onClick={() => handleSort("createdAt")}
            >
              Date created {currentSortArrow("createdAt")}
            </div>
            <div data-testid="assignee" onClick={() => handleSort("assignee")}>
              Assignee {currentSortArrow("assignee")}
            </div>
            <div
              data-testid="updated at"
              onClick={() => handleSort("updatedAt")}
            >
              Last updated {currentSortArrow("updatedAt")}
            </div>
          </IndexRow>
        )}
        {currentData().map(petition => (
          <PetitionRow
            key={petition.id}
            petition={petition}
            setPetitionsState={setPetitionsState}
          />
        ))}
      </IndexGrid>
    </>
  );
}

PetitionsTable.propTypes = {
  groupName: PropTypes.string,
  petitionGroup: PropTypes.array,
  maxPerPage: PropTypes.number,
  setPetitionsState: PropTypes.func
};
