/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { formHeaders } from "../../utils/form";
import { IndexRow } from "../Shared/IndexRow/IndexRow";
import CommonNav from "../Shared/CommonNav/CommonNav";
import SearchBar from "../Shared/SearchBar/SearchBar";
import { PaginationWidget } from "../Shared/PaginationWidget/PaginationWidget";
import { usePagination } from "../../hooks/usePagination";
import EmployeeForm from "./EmployeeForm";
import { green, red } from "../../styles/colors";
import {
  alphabeticalSort,
  booleanSort,
  chronologicalSort
} from "../../utils/sorting";

const EmployeeTable = styled.table`
  display: grid;
`;

export default function EmployeeIndexPage() {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [searchString, setSearchString] = useState("");

  const maxPerPage = 5;

  const popup = useAlert();

  useEffect(() => {
    const filtered = employees
      ? employees.filter(emp => {
          const searchables = [emp.fullName, emp.email]
            .toString()
            .toLowerCase();
          return searchables.includes(searchString.trim().toLowerCase());
        })
      : [];
    setFilteredEmployees(filtered);
  }, [searchString, employees]);

  const [loading, setLoading] = useState(false);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const url = Routes.employees_path();

      const response = await fetch(url, {
        method: "GET",
        headers: formHeaders()
      });

      if (!response.ok) {
        popup.show("Something went wrong", { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setEmployees(json.employees);
        setSearchString("");
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Sorting

  const [sortBy, setSortBy] = useState({
    attribute: "name",
    ascending: true
  });

  const handleSort = attribute => {
    setSortBy(prevState => ({
      attribute,
      ascending: prevState.attribute === attribute ? !sortBy.ascending : true
    }));
  };

  const currentSortFunction = (a, b) => {
    switch (sortBy.attribute) {
      case "name":
        return alphabeticalSort(a.fullName, b.fullName, sortBy.ascending);
      case "email":
        return alphabeticalSort(a.email, b.email, sortBy.ascending);
      case "title":
        return alphabeticalSort(a.title, b.title, sortBy.ascending);
      case "confirmed":
        return booleanSort(a.confirmed, b.confirmed, sortBy.ascending);
      case "createdAt":
        return chronologicalSort(a.createdAt, b.createdAt, sortBy.ascending);
      default:
        return chronologicalSort(
          a.confirmedAt,
          b.confirmedAt,
          sortBy.ascending
        );
    }
  };

  const currentSortArrow = attribute => {
    if (sortBy.attribute === attribute && sortBy.ascending) return "↑";
    if (sortBy.attribute === attribute && !sortBy.ascending) return "↓";
  };

  const sortedEmployees = filteredEmployees.sort((a, b) =>
    currentSortFunction(a, b)
  );

  // Pagination

  const {
    nextPage,
    prevPage,
    currentPage,
    setCurrentPage,
    maxPage,
    showAll,
    repaginate,
    currentData,
    startIndex,
    endIndex
  } = usePagination(sortedEmployees, maxPerPage);

  const [showingAll, setShowingAll] = useState(false);

  const handleShowAllClick = () => {
    showAll();
    setShowingAll(true);
  };

  const handleRepaginateClick = () => {
    repaginate();
    setShowingAll(false);
  };

  const handleSendConfirmationEmail = async personId => {
    try {
      const url = Routes.send_confirmation_instructions_employee_path(personId);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show(response.error.message, { type: "error" });
      }

      if (response.ok) {
        await response.json();
        popup.show("Confirmation email sent.", { type: "success" });
      }
    } catch (error) {
      popup.show("Something went wrong. Please contact Legalpad Engineering.", {
        type: "error"
      });
    }
  };

  return (
    <>
      <CommonNav />
      <main style={{ margin: "5rem 3rem" }}>
        <h1>Employees</h1>
        {loading && (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        )}
        <div style={{ margin: "1.5rem 0" }}>
          <SearchBar
            id="employee-index-searchbar"
            dataTestId="employee-index-searchbar"
            placeholder="Search for Legalpad Employees"
            searchStringValue={searchString}
            setSearchStringEvent={setSearchString}
          />
        </div>
        {!loading && !!filteredEmployees.length && (
          <>
            <EmployeeTable>
              <tbody>
                <IndexRow columns={4} withPagination clickable>
                  <th
                    onClick={() => handleSort("name")}
                    onKeyDown={() => handleSort("name")}
                  >
                    Name {currentSortArrow("name")}
                  </th>
                  <th onClick={() => handleSort("email")}>
                    Email {currentSortArrow("email")}
                  </th>
                  <th onClick={() => handleSort("title")}>
                    Title {currentSortArrow("title")}
                  </th>
                  <th onClick={() => handleSort("confirmed")}>
                    Confirmed {currentSortArrow("confirmed")}
                  </th>
                  <th>
                    <PaginationWidget
                      currentPage={currentPage}
                      maxPage={maxPage}
                      showingAll={showingAll}
                    >
                      <div>
                        <p>{`${startIndex} - ${endIndex} of ${filteredEmployees.length}`}</p>
                      </div>
                      {filteredEmployees.length > maxPerPage && (
                        <div>
                          {!showingAll && (
                            <a
                              data-turbolinks={false}
                              onClick={() => handleShowAllClick()}
                            >
                              Show all
                            </a>
                          )}
                          {showingAll && (
                            <a onClick={() => handleRepaginateClick()}>
                              Back to page view
                            </a>
                          )}
                        </div>
                      )}
                      {filteredEmployees.length > maxPerPage && (
                        <div>
                          <i
                            onClick={() => prevPage()}
                            className="fas fa-chevron-left"
                          />
                          <i
                            onClick={() => nextPage()}
                            className="fas fa-chevron-right"
                          />
                        </div>
                      )}
                    </PaginationWidget>
                  </th>
                </IndexRow>

                {currentData().map(emp => (
                  <IndexRow key={emp.id}>
                    <td>{emp.fullName}</td>
                    <td>{emp.email}</td>
                    <td>{emp.title}</td>
                    <td style={{ marginLeft: "1.5rem" }}>
                      {emp.confirmed ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: green }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: red }}
                        ></i>
                      )}
                    </td>
                    <td className="actions">
                      {!emp.confirmed && (
                        <button
                          type="button"
                          className="button small secondary"
                          onClick={() => handleSendConfirmationEmail(emp.id)}
                        >
                          Send confirmation email
                        </button>
                      )}
                    </td>
                  </IndexRow>
                ))}
                <EmployeeForm
                  setEmployees={setEmployees}
                  setSortBy={setSortBy}
                />
              </tbody>
            </EmployeeTable>
          </>
        )}
      </main>
    </>
  );
}

EmployeeIndexPage.propTypes = {};
