/* eslint-disable react/destructuring-assignment */
import React from "react";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import Select from "../Shared/Select";

export default class IntakeFormFields extends React.Component {
  /*
   Form to create new intakes

   Props:
   {
     intakeTypes*<array><string>         All intake types for the select box
     organizationAutocomplete*<object>   Organization autocomplete props. See child for definitions
     personAutocomplete*<object>         Peron autocomplete props. See child for definitions
     petitionAutocomplete*<object>       Petition autocomplete props. See child for definitions
   }
   */

  constructor(props) {
    super(props);
    this.state = {
      showPetitionField: props.intakeTypes[0] !== "CompanyIntake",
      personLabel: "Representative",
      petitionItems: props.petitionAutocomplete.items,
      intakeType: props.intakeTypes[0]
    };
  }

  _handleIntakeTypeChange(e) {
    const type = e.target.value;
    this._toggleInputDisplays(type);
    this._toggleLabels(type);
    this._filterPetitions(type);
    this.setState({ intakeType: type });
  }

  _toggleInputDisplays(type) {
    this.setState({
      showPetitionField:
        type !== "CompanyIntake" && type !== "ExceptionalAbilityCompanyIntake"
    });
  }

  _toggleLabels(type) {
    if (
      [
        "ExceptionalAbilityCompanyIntake",
        "CompanyIntake",
        "JobIntake"
      ].includes(type)
    ) {
      this.setState({ personLabel: "Representative" });
    } else {
      this.setState({ personLabel: "Applicant" });
    }
  }

  _filterPetitions(type) {
    if (type === "ExceptionalAbilityIntake") {
      const filteredItems = this.props.petitionAutocomplete.items.filter(item =>
        item.value.startsWith("O-1")
      );
      this.setState({ petitionItems: filteredItems });
    } else {
      this.setState({ petitionItems: this.props.petitionAutocomplete.items });
    }
  }

  _renderPetitionField() {
    return (
      <div className="field">
        <label>Petition</label>
        <AutocompleteField
          {...this.props.petitionAutocomplete}
          items={this.state.petitionItems}
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <h2>Create a new Intake:</h2>

        <div className="field">
          <Select
            name="intake[intake_type]"
            id="intake_intake_type"
            options={this.props.intakeTypes}
            value={this.state.intakeType}
            onSelect={e => this._handleIntakeTypeChange(e)}
          />
        </div>

        <div className="field">
          <label>Organization</label>
          <AutocompleteField {...this.props.organizationAutocomplete} />
        </div>

        <div className="field">
          <label>{this.state.personLabel}</label>
          <AutocompleteField {...this.props.personAutocomplete} />
        </div>

        {this.state.showPetitionField ? this._renderPetitionField() : null}
      </React.Fragment>
    );
  }
}
