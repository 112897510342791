import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { fetchCall } from "../../../utils/fetchCall";

export default function PetitionAssigneeSelect(props) {
  const { assigneeId = null, petitionId = null, assigneeOptions = [] } = props;

  const defaultOption = assigneeOptions.find(
    option => option.value === assigneeId
  );

  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const handleSelectChange = async selectedOption => {
    let selectedAssigneeId = null;
    if (selectedOption) {
      selectedAssigneeId = selectedOption.value;
    }

    const url = Routes.petition_set_assignee_path(petitionId);

    const requestBody = {
      petition_id: petitionId,
      assignee_id: selectedAssigneeId
    };

    const response = await fetchCall(url, {
      method: "PUT",
      body: JSON.stringify(requestBody)
    });

    if (!response.success) {
      alert(`Unexpected error: ${response.error}`);
    }
    if (response.success) {
      setSelectedValue(selectedOption);
    }
  };

  return (
    <Select
      data-testid="select"
      value={selectedValue}
      options={assigneeOptions}
      onChange={handleSelectChange}
      className="petition-assignee-select"
      classNamePrefix="petition-assignee"
      placeholder="Select Assignee..."
      isClearable
    />
  );
}

PetitionAssigneeSelect.propTypes = {
  assigneeOptions: PropTypes.array,
  assigneeId: PropTypes.string,
  petitionId: PropTypes.string
};
