import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CommonNav from "../../Shared/CommonNav/CommonNav";
import EvidenceRequestForm from "./EvidenceRequestForm/EvidenceRequestForm";
import EvidenceStatusPage from "./EvidenceStatusPage/EvidenceStatusPage";
import PopupMessage from "../../PopupMessage/PopupMessage";

const Container = styled.div`
  margin: 3rem 4rem 9rem 4rem;
  &&& {
    p {
      max-width: 80rem;
      font-size: 20px;
      margin-top: 1rem;
    }
  }
`;

export default function EvidenceRequestTool(props) {
  const {
    petitionId = null,
    beneficiaryName = "Unknown Beneficiary",
    evidenceOptions = [],
    evidenceRequest = {}
  } = props;

  const [errors, setErrors] = useState([]);
  const [requestStatus, setRequestStatus] = useState("IDLE");
  const [existingEvidenceRequest, setExistingEvidenceRequest] = useState(
    evidenceRequest
  );

  setRequestStatus.types = {
    idle: "IDLE",
    pending: "PENDING",
    failure: "FAILURE",
    submitted: "SUBMITTED"
  };

  const renderEvidenceRequestTool = () => {
    if (
      (requestStatus === setRequestStatus.types.idle ||
        requestStatus === setRequestStatus.types.failure) &&
      !existingEvidenceRequest
    ) {
      return (
        <EvidenceRequestForm
          petitionId={petitionId}
          evidenceOptions={evidenceOptions}
          errors={errors}
          setErrors={setErrors}
          setRequestStatus={setRequestStatus}
          setEvidenceRequest={setExistingEvidenceRequest}
        />
      );
    }
    return <EvidenceStatusPage evidenceRequest={existingEvidenceRequest} />;
  };

  return (
    <>
      {errors &&
        errors.map(error => (
          <PopupMessage
            popupType="error"
            message={error.message}
            key={Math.random()}
          />
        ))}
      <Container>
        <CommonNav
          home={{ text: "Petitions", href: "/petitions" }}
          title={{ text: beneficiaryName, href: `/petitions/${petitionId}` }}
          subTitle={{
            text: "Request Evidence",
            href: `/petitions/${petitionId}/evidence_request`
          }}
        />
        {renderEvidenceRequestTool()}
      </Container>
    </>
  );
}

EvidenceRequestTool.propTypes = {
  petitionId: PropTypes.string,
  beneficiaryName: PropTypes.string,
  evidenceOptions: PropTypes.array,
  evidenceRequest: PropTypes.object
};
