/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default function NumberField(props) {
  const {
    prefix,
    suffix,
    delimiter,
    separator,
    float,
    precision,
    signed,
    places,
    handleInputChange
  } = props;

  const [inputValue, setInputValue] = useState(props.value ? props.value : "");

  const [maskedValue, setMaskedValue] = useState(
    props.value ? props.value : ""
  );

  const cleanValue = value => parseFloat(value.toString().replace(/\$|,/g, ""));

  const handleChange = e => {
    setMaskedValue(e.target.value);
    setInputValue(cleanValue(e.target.value));
    if (handleInputChange) {
      handleInputChange(cleanValue(e.target.value));
    }
  };

  const mask = createNumberMask({
    prefix,
    suffix,
    thousandsSeparatorSymbol: delimiter,
    decimalSymbol: separator,
    allowDecimal: float || precision > 0,
    decimalLimit: precision,
    allowNegative: signed,
    integerLimit: places
  });

  return (
    <React.Fragment>
      <input type="hidden" name={props.name} value={inputValue} />
      <MaskedInput
        mask={mask}
        type="text"
        value={maskedValue}
        placeholder={props.placeholder}
        onChange={e => handleChange(e)}
        onBlur={props.handleInputBlur}
      />
      {props.label && <label>{props.label}</label>}
    </React.Fragment>
  );
}

NumberField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleInputBlur: PropTypes.func,
  prefix: PropTypes.string,
  delimiter: PropTypes.string,
  separator: PropTypes.string,
  precision: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  signed: PropTypes.bool
};
